import { createRouter, createWebHistory } from 'vue-router';
import Auth from '../views/Auth.vue';
import Main from '@/views/Main.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';
import ComponentList from '@/views/component/ComponentList.vue';
import ComponentDetail from '@/views/component/ComponentDetail.vue';
import RepairList from '@/views/repair/RepairList.vue';
import RepairDetail from '@/views/repair/RepairDetail.vue';
import StockHistoryList from '@/views/stock/StockHistoryList.vue';
import ModelComponentList from '@/views/component/ModelComponentList.vue';
import ProductList from '@/views/product/ProductList.vue';
import ProductDetail from '@/views/product/ProductDetail.vue';
import ModelList from '@/views/model/ModelList.vue';
import ModelDetail from '@/views/model/ModelDetail.vue';
import DeviceList from '@/views/device/DeviceList.vue';
import DeviceDetail from '@/views/device/DeviceDetail.vue';
import UserList from '@/views/user/UserList.vue';
import UserDetail from '@/views/user/UserDetail.vue';
import PlaceList from '@/views/place/PlaceList.vue';
import PlaceDetail from '@/views/place/PlaceDetail.vue';
import ComponentTypeList from '@/views/componentType/ComponentTypeList.vue';
import componentTypeDetail from '@/views/componentType/ComponentTypeDetail.vue';
import GroupList from '@/views/group/GroupList.vue';
import GroupDetail from '@/views/group/GroupDetail.vue';
import GoodiesList from '@/views/goodies/GoodiesList.vue';
import GoodiesDetail from '@/views/goodies/GoodiesDetail.vue';
import GoodiesTypeList from '@/views/goodiesType/GoodiesTypeList.vue';
import GoodiesTypeDetail from '@/views/goodiesType/GoodiesTypeDetail.vue';
import StockInventory from '@/views/stock/StockInventory.vue';
import BrandList from '../views/brand/BrandList.vue';
import BrandDetail from '../views/brand/BrandDetail.vue';
import Goal from '../views/goal/Goal.vue';
import AdminReportActivity from '@/views/Report/AdminReportActivity.vue';
import DocumentList from '@/views/document/DocumentList.vue';
import StockTransfer from '@/views/stock/StockTransfer.vue';
import ExportVenteList from '@/views/export/ExportVenteList.vue';
import ExportRachatList from '@/views/export/ExportRachatList.vue';
import ExportStockList from '@/views/export/ExportStockList.vue';
import AccessoryTypeList from '@/views/accessoryType/AccessoryTypeList.vue';
import AccessoryTypeDetail from '@/views/accessoryType/AccessoryTypeDetail.vue';
import TextureList from '@/views/texture/TextureList.vue';
import TextureDetail from '@/views/texture/TextureDetail.vue';
import AccessoryList from '@/views/accessory/AccessoryList.vue';
import AccessoryDetail from '@/views/accessory/AccessoryDetail.vue';
import TempDeviceList from '@/views/device/TempDeviceList.vue';
import TempDeviceDetail from '@/views/device/TempDeviceDetail.vue';
import TempRepairDetail  from '@/views/repair/TempRepairDetail.vue';
import TempRepairList from '@/views/repair/TempRepairList.vue';
import TempComponentList from '@/views/component/TempComponentList.vue';
import TempComponentDetail from '@/views/component/TempComponentDetail.vue';
import TempModelList from '@/views/model/TempModelList.vue';
import TempModelDetail from '@/views/model/TempModelDetail.vue';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'active',
    routes: [
        {
            path:'/',
            redirect: '/dashboard'
        },
        {
            name: 'auth',
            path: '/login',
            component: Auth,
            props: true,
        },
        {
            name: 'tableau-de-bord',
            path: '/dashboard',
            meta: {
                subtitle: 'Tableau de board',
            },
            component: Dashboard
        },
        {
            name: 'model-components',
            path: '/model-components/place',
            component: ModelComponentList,
            props: true,
        },
        {
            path: '/admin',
            component: Main,
            children: [
                {
                    name: 'admin-components',
                    path: 'component',
                    component: ComponentList,
                    meta: {
                        title: 'Administration des composants',
                        subtitle: 'Catalogue des composants',
                    },
                },
                {
                    path: 'component/:id',
                    component: ComponentDetail,
                    meta: {
                        title: 'Administration des composants',
                        subtitle: "Détail d'un composant",
                    },
                },
                {
                    name: 'admin-temp-component',
                    path: 'temp-component',
                    component: TempComponentList,
                    meta: {
                        title: 'Administration des demandes de création de nouveaux composants',
                        subtitle: 'Liste de demande de création des composants',
                    },
                },
                {
                    path: 'temp-component/:id',
                    component: TempComponentDetail,
                    meta: {
                        title: 'Administration des demandes de création de composants',
                        subtitle: "Détail d'une demande de création de composant",
                    },
                },
                {
                    name: 'admin-repairs',
                    path: 'repair',
                    component: RepairList,
                    meta: {
                        title: 'Administration des réparations',
                        subtitle: 'Catalogue des réparations',
                    },
                },
                {
                    path: 'repair/:id',
                    component: RepairDetail,
                    meta: {
                        title: 'Administration des réparations',
                        subtitle: "Détail d'une réparation",
                    },
                },
                {
                    name: 'admin-temp-repair',
                    path: 'temp-repair',
                    component: TempRepairList,
                    meta: {
                        title: 'Administration des demandes de création de nouvelles réparations',
                        subtitle: 'Liste des demandes de création de réparation',
                    },
                },
                {
                    path: 'temp-repair/:id',
                    component: TempRepairDetail,
                    meta: {
                        title: 'Administration des demande de création de nouvelles réparations',
                        subtitle: "Détail demande de création de réparation",
                    },
                },
                {
                    path: 'stock/:id',
                    component: StockHistoryList,
                    meta: {
                        title: 'Administration des stocks',
                        subtitle: "Historique des mouvements de stock",
                    },
                },
                {
                    name: 'admin-stocks-transfer',
                    path: 'stock-transfer',
                    component: StockTransfer,
                    meta: {
                        title: 'Transfert de stock',
                        subtitle: "Transfert de stock",
                    },
                },
                {
                    name: 'admin-stocks',
                    path: 'stock-inventory',
                    component: StockInventory,
                    meta: {
                        title: 'Administration des stocks',
                        subtitle: "Mouvements de stock",
                    },
                },
                {
                    name: 'admin-products',
                    path: 'product',
                    component: ProductList,
                    meta: {
                        title: 'Administration des produits',
                        subtitle: 'Catalogue des produits',
                    },
                },
                {
                    path: 'product/:id',
                    component: ProductDetail,
                    meta: {
                        title: 'Administration des produits',
                        subtitle: "Détail d'un produit",
                    },
                },
                {
                    name: 'admin-models',
                    path: 'model',
                    component: ModelList,
                    meta: {
                        title: 'Administration des modèles',
                        subtitle: 'Liste des modèles',
                    },
                },
                {
                    path: 'model/:id',
                    component: ModelDetail,
                    meta: {
                        title: 'Administration des modèles',
                        subtitle: "Détail d'un model",
                    },
                },
                {
                    name: 'admin-temp-model',
                    path: 'temp-model',
                    component: TempModelList,
                    meta: {
                        title: 'Administration des demandes de création de nouveaux modèles',
                        subtitle: 'Liste des demandes de création de modèles',
                    },
                },
                {
                    path: 'temp-model/:id',
                    component: TempModelDetail,
                    meta: {
                        title: 'Administration des demande de création de nouveaux modèles',
                        subtitle: "Détail demande de création de modèle",
                    },
                },
                {
                    name: 'admin-devices',
                    path: 'device',
                    component: DeviceList,
                    meta: {
                        title: 'Administration des appareils',
                        subtitle: 'Liste des appareils',
                    },
                },
                {
                    path: 'device/:id',
                    component: DeviceDetail,
                    meta: {
                        title: 'Administration des appareils',
                        subtitle: "Détail d'un appareil",
                    },
                },
                {
                    name: 'admin-component-type',
                    path: 'component-type',
                    component: ComponentTypeList,
                    meta: {
                        title: 'Administration des types de composant',
                        subtitle: 'Liste des types de composant',
                    },
                },
                {
                    path: 'component-type/:id',
                    component: componentTypeDetail,
                    meta: {
                        title: 'Administration des types de composant',
                        subtitle: "Détail d'un type de composant",
                    },
                },
                {
                    name: 'admin-goodies-type',
                    path: 'goodies-type',
                    component: GoodiesTypeList,
                    meta: {
                        title: 'Administration des types de goodies',
                        subtitle: "Liste des types de goodies",
                    },
                },
                {
                    path: 'goodies-type/:id',
                    component: GoodiesTypeDetail,
                    meta: {
                        title: 'Administration des types de goodies',
                        subtitle: "Détail d'un type de goodies",
                    },
                },
                {
                    name: 'admin-goodies',
                    path: 'goodies',
                    component: GoodiesList,
                    meta: {
                        title: 'Administration des goodies',
                        subtitle: "Liste des goodies",
                    },
                },
                {
                    path: 'goodies/:id',
                    component: GoodiesDetail,
                    meta: {
                        title: 'Administration des goodies',
                        subtitle: "Détail d'un goodies",
                    },
                },
                {
                    name: 'admin-places',
                    path: 'place',
                    component: PlaceList,
                    meta: {
                        title: 'Administration des lieux',
                        subtitle: 'Liste des lieux',
                    },
                },
                {
                    path: 'place/:id',
                    component: PlaceDetail,
                    meta: {
                        title: 'Administration des lieux',
                        subtitle: "Détail d'un lieu",
                    },
                },
                {
                    name: 'admin-users',
                    path: 'user',
                    component: UserList,
                    meta: {
                        title: 'Administration des utilisateurs',
                        subtitle: "Liste des utilisateurs",
                    },
                },
                {
                    path: 'user/:id',
                    component: UserDetail,
                    meta: {
                        title: 'Administration des utilisateurs',
                        subtitle: "Détail d'un utilisateur",
                    },
                },
                {
                    name: 'admin-groups',
                    path: 'group',
                    component: GroupList,
                    meta: {
                        title: 'Administration des groupes',
                        subtitle: "Liste des groupes",
                    },
                },
                {
                    path: 'group/:id',
                    component: GroupDetail,
                    meta: {
                        title: 'Administration des groupes',
                        subtitle: "Liste des groupes",
                    },
                },
                {
                    name: 'admin-brands',
                    path: 'brand',
                    component: BrandList,
                    meta: {
                        title: 'Administration des marques',
                        subtitle: "Liste des marques",
                    },
                },
                {
                    path: 'brand/:id',
                    component: BrandDetail,
                    meta: {
                        title: 'Administration des marques',
                        subtitle: "Liste des marques",
                    },
                },
                {
                    name: 'admin-goals',
                    path: 'goal',
                    component: Goal,
                    meta: {
                        title: 'Administration des objectifs',
                        subtitle: "Saisie des objectifs",
                    },
                },
                {
                    name: 'admin-report-activity',
                    path: 'report-activity',
                    component: AdminReportActivity,
                    meta: {
                        title: 'Administration des données du rapport d\'activité',
                        subtitle: "Saisie des données",
                    },
                },
                {
                    name: 'admin-document',
                    path: 'documents',
                    component: DocumentList,
                    meta: {
                        title: 'Documents',
                        subtitle: "Liste des documents",
                    },
                },
                {
                    name: 'admin-export-vente',
                    path: 'export-vente',
                    component: ExportVenteList,
                    meta: {
                        title: 'Export des ventes',
                        subtitle: "Exports des ventes",
                    },
                },
                {
                    name: 'admin-export-rachat',
                    path: 'export-rachat',
                    component: ExportRachatList,
                    meta: {
                        title: 'Export des rachats',
                        subtitle: "Exports des rachats",
                    },
                },
                {
                    name: 'admin-export-stock',
                    path: 'export-stock',
                    component: ExportStockList,
                    meta: {
                        title: 'Export des stocks',
                        subtitle: "Exports des stocks",
                    },
                },
                {
                    name: 'admin-accessory-type',
                    path: 'accessory-type',
                    component: AccessoryTypeList,
                    meta: {
                        title: 'Administration des types d\'accessoire',
                        subtitle: "Liste des types d\'accessoire",
                    },
                },
                {
                    path: 'accessory-type/:id',
                    component: AccessoryTypeDetail,
                    meta: {
                        title: 'Administration des types d\'accessoire',
                        subtitle: "Détail d\'un type d\'accessoire",
                    },
                },
                {
                    name: 'admin-texture',
                    path: 'texture',
                    component: TextureList,
                    meta: {
                        title: 'Administration des textures',
                        subtitle: "Liste des textures",
                    },
                },
                {
                    path: 'texture/:id',
                    component: TextureDetail,
                    meta: {
                        title: 'Administration des textures',
                        subtitle: "Détail d\'une texture'",
                    },
                },
                {
                    name: 'admin-accessory',
                    path: 'accessory',
                    component: AccessoryList,
                    meta: {
                        title: 'Administration des accessoires',
                        subtitle: "Liste des accessoires",
                    },
                },
                {
                    path: 'accessory/:id',
                    component: AccessoryDetail,
                    meta: {
                        title: 'Administration des accessoires',
                        subtitle: "Détail d\'un accessoires'",
                    },
                },
                {
                    name: 'admin-temp-device',
                    path: 'temp-device',
                    component: TempDeviceList,
                    meta: {
                        title: 'Appareil temporaire',
                        subtitle: "Liste des appareils temporaires",
                    },
                },
                {
                    path: 'temp-device/:gencode',
                    component: TempDeviceDetail,
                    meta: {
                        title: 'Appareil temporaire',
                        subtitle: "Détail d'un appareil temporaire",
                    },
                },
            ],
        },
    ],
});

export default router;
