<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-8'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <div class="row justify-content-between">
                                <h3 class='card-title' v-if='detail.componentId'>
                                    {{ detail.name }}
                                </h3>
                                <h3 class='card-title' v-else>Création d'un composant</h3>
                                <button v-if="!loading && detail.componentId !== null && suppression"
                                        class="btn btn--color-danger"
                                        @click="openDeleteModal">
                                    <svg-bin />
                                </button>
                            </div>
                            <form class='mt-6'>
                                <fieldset>
                                    <legend>Composant</legend>
                                    <!-- TYPE COMPOSANT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select :class="{'is-invalid': v$.detail.componentTypeId.$error,}"
                                                    class='form-control'
                                                    v-model='detail.componentTypeId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(componentType, key) in componentTypes'
                                                        :key='key'
                                                        :value='componentType.componentTypeId'>
                                                    {{ componentType.name }}
                                                </option>
                                            </select>
                                            <p v-if='v$.detail.componentTypeId.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner un type de composant
                                            </p>
                                        </div>
                                    </div>
                                    <!--                                    &lt;!&ndash; CATEGORIE &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Catégorie</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <select class='form-control'-->
                                    <!--                                                    v-model='detail.categoryId'-->
                                    <!--                                                    required>-->
                                    <!--                                                <option :value="undefined"></option>-->
                                    <!--                                                <option v-for='(category, key) in categories'-->
                                    <!--                                                        :key='key'-->
                                    <!--                                                        :value='category.categoryId'>-->
                                    <!--                                                    {{ category.name }}-->
                                    <!--                                                </option>-->
                                    <!--                                            </select>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!-- ETAT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Etat <abbr>*</abbr></label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='status'
                                                               :class="{'is-invalid': v$.detail.status.$error}"
                                                               :id="'status_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.status'>
                                                        <label class='custom-control-label'
                                                               :for="'status_'+s.value"
                                                               :class="detail.status === s.value ? s.class : ''">{{ s.label
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row' v-if='v$.detail.status.$error'>
                                                <div class='col'>
                                                    <input type='hidden' class='form-control is-invalid' >
                                                    <p class='invalid-feedback'>
                                                        Veuillez sélectionner un état
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- QUALITE COMPOSANT -->
                                    <div class='row form-group align-items-center'>
                                        <label class='col-3 col-form-label'>Qualité du composant</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.componentQualityId'>
                                                <option :value="undefined"></option>
                                                <option v-for='(componentQuality, key) in componentQualities'
                                                        :key='key'
                                                        :value='componentQuality.componentQualityId'>
                                                    {{ componentQuality.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- UGS -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>UGS</label>
                                        <div class='col-6'>
                                            <input type='text'
                                                   class='form-control'
                                                   v-model='detail.ugs'>
                                        </div>
                                    </div>
                                    <!-- REF FABRICANT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Référence fabriquant</label>
                                        <div class='col-6'>
                                            <input type='text'
                                                   class='form-control'
                                                   v-model='detail.manufacturerRef' >
                                        </div>
                                    </div>
                                    <!-- OUTILLAGE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Outillage</label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='tools'
                                                               :class="{'is-invalid': v$.detail.status.$error}"
                                                               :id="'tools_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.tools'>
                                                        <label class='custom-control-label'
                                                               :for="'tools_'+s.value"
                                                               :class="detail.tools === s.value ? s.class : ''">{{ s.label2
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- PRIX -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Prix HT</label>
                                        <div class='col-6'>
                                            <input type='text'
                                                   class='form-control'
                                                   v-model='detail.price' >
                                        </div>
                                    </div>
                                    <!-- PRIX FOUNISSEUR -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Prix fournisseur</label>
                                        <div class='col-6'>
                                            <input type='text'
                                                   class='form-control'
                                                   v-model='detail.supplierPrice'
                                                   readonly >
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>Appareil</legend>
                                    <!-- TYPE PRODUIT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.productTypeId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(productType, key) in productTypes'
                                                        :key='key'
                                                        :value='productType.productTypeId'>
                                                    {{ productType.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- MARQUE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Marque</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.brandId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(brand, key) in brands'
                                                        :key='key'
                                                        :value='brand.brandId'>
                                                    {{ brand.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- MODELE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Modèle</label>
                                        <div class='col-6'>
                                            <VueMultiselect v-model='detail.models'
                                                            :options='models'
                                                            :multiple="true"
                                                            :taggable="true"
                                                            placeholder="Chercher un model"
                                                            label="name"
                                                            track-by="modelId"/>
                                        </div>
                                    </div>
                                    <!-- COULEUR -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Couleur</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.colorId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(color, key) in colors'
                                                        :key='key'
                                                        :value='color.colorId'>
                                                    {{ color.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- CAPACITE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Capacité</label>
                                        <div class='col-6'>
                                            <div class='row'>
                                                <div class='col-3 pl-2'>
                                                    <select class='form-control'
                                                            v-model='detail.capacityId'
                                                            required
                                                            :disabled="!detail.unitId">
                                                        <option :value="undefined"></option>
                                                        <option v-for='(capacity, key) in capacities'
                                                                :key='key'
                                                                :value='capacity.capacityId'>
                                                            {{ capacity.value }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class='col-3'>
                                                    <select class='form-control'
                                                            v-model='detail.unitId'
                                                            required>
                                                        <option :value="undefined"></option>
                                                        <option v-for='(unit, key) in units'
                                                                :key='key'
                                                                :value='unit.unitId'>
                                                            {{ unit.shortName }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-center'>
                                <div class='col-auto'>
                                    <notif type='success'
                                           text='Sauvegarde effectuée'
                                           v-if='success'/>
                                    <notif type='error'
                                           text='Un problème est survenu durant la sauvegarde'
                                           :message='this.msgError'
                                           v-if='error'/>
                                </div>
                            </div>
                            <div class='row justify-content-between is-animated d-flex'>
                                <div class="col-auto">
                                    <button v-if="!loading && detail.componentId !== null && modification"
                                            class="btn--color-success"
                                            @click="createComponent">
                                        <span>Créer un nouveau composant</span>
                                        <svg-add />
                                    </button>
                                </div>
                                <div class='col-auto'>
                                    <button
                                        v-if='!loading && modification'
                                        class='btn--color-success'
                                        @click='submit'
                                        :disabled='v$.$error'>
                                        <span>Sauvegarder</span>
                                        <svg-floppy-disk />
                                    </button>
                                    <button
                                        v-if='loading'
                                        class='btn--color-success'
                                        type='button'
                                        disabled>
                                        <span>Sauvegarde en cours...</span>
                                        <figure class='spin--infinite mr-2'>
                                            <svg-floppy-disk />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-sm-4'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Image</h3>
                            <div class='row justify-content-center'>
                                <div class='col-auto' v-if='detail.image && !image.error'>
                                    <img class='img-fluid' :src='detail.image' >
                                </div>
                                <div class='col-auto' v-if='!detail.image || image.error'>
                                    <notif type='error'
                                           :text='image.errorText'/>
                                </div>
                            </div>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-end'>
                                <div class='col-auto is-animated d-flex'>
                                    <input type='file' id='fileUpload' @change='handleImage' hidden >
                                    <button v-if='!image.loading && modification'
                                            class='btn--outline'
                                            @click='selectFile'>
                                        <span>Modifier l'image</span>
                                        <svg-download />
                                    </button>
                                    <button v-if='image.loading'
                                            class=''
                                            type='button'
                                            disabled>
                                        <span>Upload en cours...</span>
                                        <figure class='spin--infinite ml-2'>
                                            <svg-download />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                            <div class='row justify-content-end text-demat mr-1 mt-1 font-italic'>
                                <small>Format: JPG/PNG - Max: 300Ko</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class='col-sm-12'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Stock</h3>
                            <div class='row justify-content-center'>
                                <div class='col-12' >
                                    <table
                                        ref="table"
                                        class="table table-striped table-hover table-handyRows"></table>
                                </div>
                                <div class='col-auto' v-if='!detail.stocks || stocks.error || !detail.stocks.length'>
                                    <notif type='error'
                                           :text='stocks.errorText'/>
                                </div>
                            </div>
                        </div>
                        <!--                        <div class='card-footer'>-->
                        <!--                            <div class='row justify-content-end'>-->
                        <!--                                <div class='col-auto is-animated d-flex'>-->
                        <!--                                    <button class='btn&#45;&#45;outline'-->
                        <!--                                            @click='openStockModal'-->
                        <!--                                            v-if="modification">-->
                        <!--                                        <span>Mouvement de stock</span>-->
                        <!--                                        <svg-two-arrows />-->
                        <!--                                    </button>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal mouvement de stock -->
        <modal title="Mouvement de stock" :display="stockModal" @close="closeModal" >
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-12">
                            <fieldset>
                                <legend>Informations</legend>
                                <div class="form-group row">
                                    <label class='col-3 col-form-label'>Type</label>
                                    <div class='col-6'>
                                        <select class='form-control'
                                                v-model='movement.type'
                                                required>
                                            <option v-for='(movementType, key) in movementTypes'
                                                    :key='key'
                                                    :value='movementType.id'>
                                                {{ movementType.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <!--                                <div class='form-group row' v-if="movement.type === 'in'">-->
                                <!--                                    <label class='col-3 col-form-label'>QrCode</label>-->
                                <!--                                    <div class='col-6 is-animated d-flex'>-->
                                <!--                                        <div class='col-12' v-if='movement.qrcode'>-->
                                <!--                                            <img class='img-fluid' :src='movement.qrcode' />-->
                                <!--                                        </div>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                                <!--                                <div class="form-group row justify-content-center" v-if="movement.type === 'in'">-->
                                <!--                                    <input type='file' id='qrcode' @change='handleQrCode' hidden />-->
                                <!--                                    <button v-if='!image.loading'-->
                                <!--                                            class='btn&#45;&#45;outline'-->
                                <!--                                            @click='selectQrcode'>-->
                                <!--                                        <span>Télécharger QrCode</span>-->
                                <!--                                        <svg-download />-->
                                <!--                                    </button>-->
                                <!--                                    <button v-if='image.loading'-->
                                <!--                                            class=''-->
                                <!--                                            type='button'-->
                                <!--                                            disabled>-->
                                <!--                                        <span>Upload en cours...</span>-->
                                <!--                                        <figure class='spin&#45;&#45;infinite ml-2'>-->
                                <!--                                            <svg-download />-->
                                <!--                                        </figure>-->
                                <!--                                    </button>-->
                                <!--                                </div>-->
                                <!--                                <div class='row justify-content-center text-demat mr-1 mt-1 font-italic' v-if="movement.type === 'in'">-->
                                <!--                                    <small>Format: JPG/PNG - Max: 300Ko</small>-->
                                <!--                                </div>-->
                                <div class="form-group row">
                                    <label class='col-3 col-form-label'>Lieu</label>
                                    <div class='col-6'>
                                        <select class='form-control'
                                                v-model='movement.place'
                                                required>
                                            <option :value="undefined"></option>
                                            <option v-for='(place, key) in places'
                                                    :key='key'
                                                    :value='place.placeId'>
                                                {{ place.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <!--                                <div class="form-group row" v-if="movement.place === '2'">-->
                                <!--                                    <label class='col-3 col-form-label'>Emplacement</label>-->
                                <!--                                    <div class='col-6'>-->
                                <!--                                        <select class='form-control'-->
                                <!--                                                v-model='movement.location'-->
                                <!--                                                required>-->
                                <!--                                            <option :value="undefined"></option>-->
                                <!--                                            <option v-for='(location, key) in locations'-->
                                <!--                                                    :key='key'-->
                                <!--                                                    :value='location.physicalLocationId'>-->
                                <!--                                                {{ location.name }}-->
                                <!--                                            </option>-->
                                <!--                                        </select>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                                <div class="form-group row" v-if="movement.type === 'in'">
                                    <label class='col-3 col-form-label'>Fournisseur <abbr>*</abbr></label>
                                    <div class='col-6'>
                                        <select class='form-control'
                                                v-model='movement.supplier'
                                                :class="{'is-invalid': v$.movement.supplier ? v$.movement.supplier.$error : false}"
                                                required>
                                            <option :value="undefined"></option>
                                            <option v-for='(supplier, key) in suppliers'
                                                    :key='key'
                                                    :value='supplier.id'>
                                                {{ supplier.value }}
                                            </option>
                                        </select>
                                        <p v-if='v$.movement.supplier ? v$.movement.supplier.$error : false'
                                           class='invalid-feedback'>
                                            Veuillez sélectionner le fournisseur
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group row" v-if="movement.type === 'in'">
                                    <label class='col-3 col-form-label'>Prix unitaire <abbr>*</abbr></label>
                                    <div class='col-6'>
                                        <input class='form-control'
                                               type="text"
                                               :class="{'is-invalid': v$.movement.unitPrice ? v$.movement.unitPrice.$error : false}"
                                               v-model='movement.unitPrice'
                                               required>
                                        <p v-if='v$.movement.unitPrice && v$.movement.unitPrice.$error'
                                           class='invalid-feedback'>
                                            Veuillez sélectionner le prix unitaire
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group row" v-if="movement.type === 'out'">
                                    <label class='col-3 col-form-label'>Motif <abbr>*</abbr></label>
                                    <div class='col-6'>
                                        <select class='form-control'
                                                :class="{'is-invalid': v$.movement.comment ? v$.movement.comment.$error : false}"
                                                v-model='movement.comment'
                                                required>
                                            <option :value="'Vente'">Vente</option>
                                            <option :value="'SAV'">SAV</option>
                                            <option :value="'Casse'">Casse</option>
                                        </select>
                                        <p v-if='v$.movement.comment ? v$.movement.comment.$error : false'
                                           class='invalid-feedback'>
                                            Veuillez sélectionner un motif pour la sortie du composant
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class='col-3 col-form-label'>Quantité</label>
                                    <div class='col-6'>
                                        <input class='form-control'
                                               type="number"
                                               min="0"
                                               v-model='movement.quantity'
                                               :class="{'is-invalid': v$.movement.quantity ? v$.movement.quantity.$error : false}"
                                               required>
                                        <p v-if='v$.movement.quantity ? v$.movement.quantity.$error : false'
                                           class='invalid-feedback'>
                                            Veuillez sélectionner une quantité
                                        </p>
                                    </div>
                                </div>
                                <input type="text" :value="detail.componentId" hidden>
                            </fieldset>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn--outline btn--color--danger" @click="closeModal" >
                    <svg-close />
                    <span>Annuler</span>
                </button>
                <button type="button" class="btn--color-danger" @click="addMovement" :disabled='v$.$error'>
                    <span>Enregistrer</span>
                    <svg-floppy-disk />
                </button>
            </div>
        </modal>
        <!-- Modal suppression component -->
        <modal title="Suppression du composant" :display="deleteModal" @close="closeDeleteModal" >
            <div class="modal-body">
                <div class="row justify-content-center">
                    <div class="col-12">
                        Êtes vous sûr de vouloir supprimer le composant ?
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn--outline btn--color--danger" @click="closeDeleteModal" >
                    <svg-close />
                    <span>Annuler</span>
                </button>
                <button type="button" class="btn--color-danger" @click="deleteComponent" :disabled='v$.$error'>
                    <span>Supprimer</span>
                    <svg-bin />
                </button>
            </div>
        </modal>
    </section>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';
import { useVuelidate } from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import modal from '../../components/Modal.vue';
import VueMultiselect from 'vue-multiselect';
import 'datatables.net/js/jquery.dataTables';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';
import 'datatables.net-buttons/js/buttons.html5';

import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import { useAuthStore } from '@/stores/auth.store';

export default {
    name: 'ComponentDetail',
    components: {
        ...svgComponent,
        notif,
        modal,
        VueMultiselect
    },
    data() {
        return {
            detail: null,
            componentTypes: [],
            componentQualities: [],
            colors: [],
            units: [],
            brands: [],
            capacities: [],
            productTypes: [],
            models: [],
            places: [],
            categories: [],
            status: [
                {
                    value: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            image: {
                loading: false,
                error: false,
                errorText: 'Aucune image',
            },
            stocks: {
                error: false,
                errorText: 'Cette pièce n\'est pas en stock'
            },
            stockModal: false,
            deleteModal: false,
            movement: {},
            movementTypes: [{ id: 'in', label: 'Entrée' }, { id: 'out', label: 'Sortie' }],
            locations: [],
            suppliers: [
                { id:'MOBILAX', value:'MOBILAX' },
                { id:'UTOPYA', value:'UTOPYA' },
                { id:'EBAY', value:'EBAY' },
                { id:'MACINFO', value:'MACINFO' },
                { id:'CRD', value:'CRD' },
                { id:'AUTRE', value:'AUTRE' }
            ],
            columns: [
                {
                    data: 'stockId',
                    title: 'Id',
                    render: function(data) {
                        return data ? '<a href="/admin/stock/' + data + '">' + data + '</a>' : '';
                    }
                }, {
                    data: 'placeName',
                    title: 'Lieu',
                }, {
                    data: 'physicalLocationName',
                    title: 'Emplacement Physique',
                },{
                    data: 'quantity',
                    title: 'Quantité',
                }, {
                    data: 'bookingQuantity',
                    title: 'Réservé',
                }
            ],
            table: null,
            lecture: false,
            modification: false,
            suppression: false,
            create: false
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des composants', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des composants', 'Lecture');
        this.suppression = await this.authStore.moduleRight('Administration des composants', 'Suppression');
        if(!this.lecture)
            this.$router.push("/admin/component");
        await this.getBrands();
        await this.getProductTypes();
        await this.getComponentTypes();
        await this.getComponentQualities();
        await this.getColors();
        await this.getUnits();
        await this.getPlaces();
        await this.getCategories();
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/component');
            this.create = true;
            this.createNewDetail();
        } else {
            await this.getDetail();
        }
    },
    watch: {
        'detail.brandId': async function (val, oldVal) {
            if(val !== oldVal && this.detail.productTypeId)
                await this.getModels();
        },
        'detail.productTypeId' : async function (val, oldVal) {
            if (val !== oldVal && this.detail.brandId)
                await this.getModels();
        },
        'detail.componentId': async function (val, oldVal) {
            if (val !== oldVal)
                await this.getStocks();
        },
        'movement.place': async function (val, oldVal) {
            if (val !== oldVal && val === '2')
                await this.getPhysicalLocations();
        },
        'detail.unitId' : async function (val, oldVal) {
            if (val !== oldVal)
                await this.getCapacities();
        }
    },
    methods: {
        createNewDetail() {
            this.detail = {
                componentId: null,
                ugs: null,
                price: null,
                image: null,
                manufacturerRef: null,
                compatibility: null,
                status: true,
                componentTypeId: null,
                componentQualityId: null,
                capacityId: null,
                value: null,
                unitId: null,
                colorId: null,
                brandId: null,
                brandName: null,
                productTypeId: null,
                productName: null,
                tools: false,
                models: [],
                stocks: []
            };
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        createComponent() {
            this.createNewDetail();
            this.$router.push("create");
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/component/${this.$route.params.id}/detail`,
                );
                this.detail = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getModels() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/models/brand/${this.detail.brandId}/productType/${this.detail.productTypeId}`,
                );
                this.models = res.data.filter(m => m.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getComponentTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/componentType`,
                );
                this.componentTypes = res.data.filter(ct => ct.status === true);
            } catch (err) {
                console.log(err);
            }
        },async getCategories() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/category`,
                );
                this.categories = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getComponentQualities() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/componentQuality`,
                );
                this.componentQualities = res.data.filter(cq => cq.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getColors() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/color`,
                );
                this.colors = res.data.filter(c => c.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getUnits() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/unit`,
                );
                this.units = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getCapacities() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/capacities/unit/${this.detail.unitId}`,
                );
                this.capacities = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getStocks() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/stocks/component/${this.detail.componentId}`,
                );
                this.detail.stocks = res.data;
                this.table = this.initDt(this.columns, this.detail.stocks);
            } catch (err) {
                console.log(err);
            }
        },
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data.filter(p => p.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getPhysicalLocations() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/physicalLocations/place/${this.movement.place}`,
                );
                this.locations = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getStockHistory() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/stock/${this.movement.place}/history`,
                );
                this.locations = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        selectFile() {
            document.getElementById('fileUpload').click();
        },
        selectQrcode() {
            document.getElementById('qrcode').click();
        },
        handleImage(e) {
            const img = e.target.files[0];

            if (['image/jpeg', 'image/png'].indexOf(img.type) === -1) {
                this.image.error = true;
                return this.image.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
            }

            if (img.size > 300000) {
                this.image.error = true;
                return this.image.errorText = 'Veuillez uploader une image inférieure à 300ko.';
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                this.uploadImage(e.target.result);
            };

            reader.readAsDataURL(img);
        },
        async uploadImage(image) {
            this.image.loading = true;
            this.image.error = false;

            try {
                const data = {
                    newImage: image,
                    oldImage: this.detail.image,
                };

                const res = await this.axios.post(`${window.location.origin}/api/crd/component/${this.detail.componentId}/image`, data);
                this.image.loading = false;
                this.detail.image = res.data.image;
            } catch (err) {
                this.image.error = true;
                this.image.loading = false;
            }
        },
        handleQrCode(e) {
            const img = e.target.files[0];

            if (['image/jpeg', 'image/png'].indexOf(img.type) === -1) {
                this.image.error = true;
                return this.image.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
            }

            if (img.size > 300000) {
                this.image.error = true;
                return this.image.errorText = 'Veuillez uploader une image inférieure à 300ko.';
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                this.movement.qrcode = e.target.result;
            };

            reader.readAsDataURL(img);
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                this.detail.price = this.detail.price === '' ? null : this.detail.price;

                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/component`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    this.goTo(res.data.componentId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
        openStockModal() {
            this.stockModal = true;
        },
        closeModal() {
            this.stockModal = false;
            this.movement = {};
        },
        openDeleteModal() {
            this.deleteModal = true;
        },
        closeDeleteModal() {
            this.deleteModal = false;
        },
        async deleteComponent() {
            try{
                await this.axios.delete(
                    `${window.location.origin}/api/crd/component/${this.detail.componentId}`
                );
                this.$router.push("/admin/component");
            }catch (err) {
                console.log(err);
                this.error = true;
                this.msgError = err.response.data.message;
            } finally {
                this.closeDeleteModal();
            }
        },
        async addMovement() {
            if (!this.v$.$error) {
                try {
                    this.movement.unitPrice = parseFloat(this.movement.unitPrice);
                    this.movement.comment = 'Depuis fiche composant';
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/stock/component/${this.detail.componentId}`,
                        this.movement
                    );
                    this.detail.stocks = res.data;
                    this.table.clear();
                    this.table.destroy();
                    this.table = this.initDt(this.columns, this.detail.stocks);

                    this.success = true;
                } catch (err) {
                    console.log(err);
                    this.error = true;
                    this.msgError = err.response.data.message;
                } finally {
                    this.closeModal();
                }
            }
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);

            const dtOptions = {
                data,
                columns,
                ordering: true,
                info: false,
                rowReorder: {
                    enabled: true
                },
                filter: false,
                language: {
                    url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json'
                },
                paging: false,
                dom: 't',
                order: [[2, 'asc']],
                select: {
                    style: 'api',
                    toggleable: false
                },
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);

                    $(vm.$refs.table).on('click', 'td', function() {
                        const row = vm.table.row(this).data();
                        const cell = vm.table.cell(this).data();
                        if (row && row.stockId !== cell) {
                            vm.movement.place = row.placeId;
                            vm.movement.location = row.physicalLocationId;
                            vm.openStockModal();
                        }
                    });
                },
            };

            return $(this.$refs.table).DataTable(dtOptions);
        }
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore()
    }),
    validations() {
        let base = {
            $autoDirty: true,
            detail: {
                componentTypeId: { required },
                status: { required },
            },
            movement: {
            }
        };
        if(this.movement.type) {
            if(this.movement.type === 'in') {
                base.movement.unitPrice = { required };
                base.movement.supplier = { required };
            }else{
                base.movement.comment = { required };
            }
            base.movement.quantity = { required };
        }
        return base;
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
