<template>
    <div class='backoffice-layout theme-bookstore' :class='classEnv'>
        <grapp-menu :class="{ 'sidebar--active': menu.displayed }" />
        <header id='mainHeader'>
            <div class='container-fluid row align-items-center justify-content-between m-0  p-0 h-100'>
                <div id='mainLogo' class='col-lg-auto d-none d-lg-block p-0'>
                    <button ui-sref='css-home' @click="this.$router.push({ name: 'tableau-de-bord' })">
                        <img src='../../assets/grapp/dist/assets/images/cerebro-removebg-preview6.png'
                             alt='CEREBRO Back-office'>
                    </button>
                </div>
                <div class='col-auto p-0 justify-content-start'>
                    <button type='button' @click='menu.toggleDisplay' class='btn btn--menu btn--color-black py-1 mx-2 px-2'>
                        <svg-menu />
                        <span class='d-none d-sm-block'>Menu</span>
                    </button>
                </div>
                <div class='col col-xl-4 p-2'>
                    <h1 class='mt-n1'><small><strong>CEREBRO ({{ version }})</strong></small></h1>
                    <h2>{{ $route.meta.subtitle }}</h2>
                </div>
                <div class='col col-xl-4 text-center d-none d-lg-block'>
                    <h3>{{ date }}<br>{{ time }}</h3>
                </div>
                <div class='col-auto d-flex align-items-center justify-content-end'>
                    <h2 class='d-none d-lg-block pr-2'>{{ authStore.getDisplayName.toUpperCase() }}</h2>
                    <button @click='logout'>
                        <svg-logout />
                    </button>
                </div>
            </div>
        </header>
        <grapp-loader :active="loader.is('load')"
                      :message="'Chargement en cours...'"/>
        <section id='mainContent'>
            <div class='container-fluid h-100'>
                <div class='row h-100'>
                    <div class='col-sm-12 h100'>
                        <div class='card shadow-sm h-100'>
                            <div class='card-body'>
                                <h3 class='card-title'>Tableau de bord</h3>
                                <notif type="error"
                                       text="Vous n'êtes pas autorisé à consulter ce module."
                                       v-if="!lecture"/>
                                <div v-if="lecture">
                                    <div class='row align-items-center'>
                                        <div class='col-lg-3 col-12'>
                                            <button type="button"
                                                    class="btn--icon btn--outline btn--small move-left"
                                                    @click='dayBefore'><svg-arrow-left /></button>
                                            <datepicker v-model='salesDate'
                                                        name='firstDay'
                                                        @input='selectDate'
                                                        :disabled-dates="{from: new Date()}"
                                                        :prevent-disable-date-selection="true"
                                                        language='fr'
                                                        :monday-first='true'
                                                        input-class='wrapperClass'
                                                        style='margin: 0 5px;' />
                                            <button type="button"
                                                    class="btn--icon btn--outline btn--small move-right"
                                                    @click='dayAfter'><svg-arrow-right /></button>
                                        </div>
                                        <div class='col-lg-2 col-12'>
                                            <select class='form-control'
                                                    v-if='!place'
                                                    v-model='type'
                                                    @change='getSales'
                                                    required>
                                                <option value='day'>Journalier</option>
                                                <option value='week'>Hebdomadaire</option>
                                                <option value='month'>Mensuel</option>
                                                <option value='year'>Annuel</option>
                                            </select>
                                        </div>
                                        <div class='col-lg-2 col-12'>
                                            <select class='form-control'
                                                    v-model='sign'
                                                    @change='filterSign'
                                                    :disabled="oneSign"
                                                    required>
                                                <option :value=null>Sélectionner une marque</option>
                                                <option value='BMP'>BeeMyPhone</option>
                                                <option value='C&R'>Cash & Repair</option>
                                            </select>
                                        </div>
                                        <div class='col-lg-2 col-12' v-if="!groupSale">
                                            <select class='form-control'
                                                    v-model='place'
                                                    @change='filterSale'
                                                    v-if="type === 'day'"
                                                    required>
                                                <option :value=null>Sélectionner un lieu</option>
                                                <option v-for='(place, key) in places'
                                                        :key='key'
                                                        :value='place'>
                                                    {{ place.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-auto" v-if="authStore.getUser.networks.length && numberGroup && !place">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="switchGroup" v-model="groupSale">
                                                <label class="custom-control-label" for="switchGroup">Chiffre du groupe</label>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class='row mt-4' v-if='!place && !groupSale'>
                                        <div class='col-md-12'>
                                            <div>
                                                <Bar ref="chartDay"
                                                     v-if="loaded"
                                                     :data='salesDayData'
                                                     :options='chartDayOptions'
                                                     :style='myStyles'
                                                     :plugins='chartPlugins'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4" v-if='!place && groupSale'>
                                        <div class='col-md-6'>
                                            <div>
                                                <Bar ref="group"
                                                     v-if="loaded"
                                                     :data="salesGroup"
                                                     :options="chartGroupOptions"
                                                     :plugins='chartPlugins'
                                                     :style="myStylesLine"/>
                                            </div>
                                        </div>
                                        <div class='col-md-6'>
                                            <div>
                                                <Bar ref='groupDetail'
                                                     :data="salesGroupDetail"
                                                     :options="chartGroupOptions"
                                                     :plugins='chartPlugins'
                                                     :style="myStylesLine"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='mt-4' v-if='place'>
                                        <div class="row mb-8" >
                                            <div class='col-12 text-center'>
                                                <h1><strong>TABLEAU DE BORD - {{ isBmp ? 'MAGASIN' : 'ATELIER'}} {{ place.name }}</strong></h1>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-lg-6">
                                                <h5 v-if='!isBmp' style="text-align: center; margin-bottom: 20px;">
                                                    <span class="p-2" :class="this.isBmp ? 'bg-orange-linear' : 'bg-green-linear'">REPARATIONS</span></h5>
                                                <h5 v-else style="text-align: center; margin-bottom: 20px;">
                                                    <span class="p-2" :class="this.isBmp ? 'bg-orange-linear' : 'bg-green-linear'">VENTES</span></h5>
                                                <!-- JOUR -->
                                                <div class="row" :class="this.isBmp ? 'bg-dark' : 'bg-green'">
                                                    <div class="col-lg-3 col-12 text-center text-white align-self-center" >
                                                        Chiffre d'affaires du jour
                                                    </div>
                                                    <div class="col-lg-5 col-12 text-center bg-grey-light pt-2">
                                                        <ul class='list-unstyled'>
                                                            <li><strong>CA HT : {{ $filters.decimal(salesPlace.day.CA) || '0,00'}}€ </strong></li>
                                                            <li>Objectif : {{ $filters.decimal(salesPlace.day.goal) || '0,00'}}€</li>
                                                        </ul>
                                                        <Doughnut ref='dayGoal'
                                                                  :data='salesDayGoalData'
                                                                  :options='chartGoalOptions'
                                                                  :style='myStylesGoal'
                                                                  :plugins='chartPlugins'/>
                                                        <ul class='list-unstyled'>
                                                            <li class='mb-1'><span v-if="raiseDay && saleDayNYear" :class="raiseDayClass">{{ formatRaiseDayValue }}</span></li>
                                                            <li> N-1 : {{ $filters.decimal(saleDayNYear) || '' }}€</li>
                                                            <li>Moy. réseau : {{ $filters.decimal(averageNetworkDay) }}€</li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-lg-4 col-12 bg-grey-light p-2 f-size-list">
                                                        <ul v-if='salesPlace' class="list-unstyled">
                                                            <li v-if="!isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-shopping-cart-1/>
                                                                    </span>Panier Moyen/Client HT : {{ $filters.decimal(salesPlace.day.averageSale) }}€
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-broken/>
                                                                    </span>
                                                                    Prises en charge : {{ salesPlace.day.initiatedRepairsALL }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-alarm-bell/>
                                                                    </span>
                                                                    Attente réparation : {{ salesPlace.day.initiatedRepairsWFR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-alarm-bell/>
                                                                    </span>
                                                                    Attente client : {{ salesPlace.day.initiatedRepairsWFC }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-alarm-bell/>
                                                                    </span>
                                                                    Attente pièce : {{ salesPlace.day.initiatedRepairsWFP }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-alarm-bell/>
                                                                    </span>
                                                                    Attente devis : {{ salesPlace.day.initiatedRepairsWFQ }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Livrées : {{ salesPlace.day.finishedRepairsRTN }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Réparées : {{ salesPlace.day.finishedRepairsRPR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Irréparables : {{ salesPlace.day.finishedRepairsUNR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Sans réparation : {{ salesPlace.day.finishedRepairsRSR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Non réparées : {{ salesPlace.day.finishedRepairsNTR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cog/>
                                                                    </span>
                                                                    SAV : {{ salesPlace.day.sav || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="this.isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Nb vente : {{ salesPlace.day.nbSale || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="this.isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-action-data-transfer/>
                                                                    </span>
                                                                    Echange  : {{ salesPlace.day.nbForfait || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-shopping-cart-1/>
                                                                    </span>Panier Moyen/Client HT : {{ $filters.decimal(salesPlace.day.averageSale) }}€
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-accounting-document/>
                                                                    </span>
                                                                    Nb remboursement : {{ salesPlace.day.nbRefund || 0 }}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>
                                                                    Mnt Remb. HT : {{ $filters.decimal(parseFloat(salesPlace.day.amountRefund)) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-charger/>
                                                                    </span>Nb accessoire : {{ salesPlace.day.nbAccessory || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>Mnt accessoire HT : {{ $filters.decimal(salesPlace.day.amountAccessory) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-action-warning/>
                                                                    </span>Nb pose de film : {{ salesPlace.day.nbHydrogel || 0 }}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>Pose de film HT : {{ $filters.decimal(salesPlace.day.amountHydrogel) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <!-- SEMAINE -->
                                                <div class="row mt-3" :class="this.isBmp ? 'bg-dark' : 'bg-green'">
                                                    <div class="col-lg-3 col-12 text-center text-white align-self-center" >
                                                        Chiffre d'affaires de la semaine
                                                    </div>
                                                    <div class="col-lg-5 col-12 text-center bg-grey-light pt-2">
                                                        <ul class='list-unstyled'>
                                                            <li><strong>CA HT: {{ $filters.decimal(salesPlace.week.CA) || '0,00' }}€</strong></li>
                                                            <li>Objectif: {{ $filters.decimal(salesPlace.week.goal) || '0,00' }}€</li>
                                                        </ul>
                                                        <Doughnut ref='weekGoal'
                                                                  :data='salesWeekGoalData'
                                                                  :options='chartGoalOptions'
                                                                  :style='myStylesGoal'
                                                                  :plugins='chartPlugins'/>
                                                        <ul class='list-unstyled'>
                                                            <li class='mb-1'><span v-if="raiseWeek && saleWeekNYear" :class="raiseWeekClass">{{ formatRaiseWeekValue }}</span></li>
                                                            <li> N-1 : {{ $filters.decimal(saleWeekNYear) || '' }}€</li>
                                                            <li>Moy. réseau : {{ $filters.decimal(averageNetworkWeek) }}€</li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-lg-4 col-12 bg-grey-light p-2 f-size-list">
                                                        <ul v-if='salesPlace' class="list-unstyled">
                                                            <li v-if="!isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-shopping-cart-1/>
                                                                    </span>Panier Moyen/Client HT :
                                                                    {{ $filters.decimal(salesPlace.week.averageSale) }}€
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-broken/>
                                                                    </span>
                                                                    Prises en charge : {{ salesPlace.week.initiatedRepairsALL }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-alarm-bell/>
                                                                    </span>
                                                                    En attente : {{ salesPlace.week.initiatedWaiting }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Livrées : {{ salesPlace.week.finishedRepairsRTN }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Réparées : {{ salesPlace.week.finishedRepairsRPR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Irréparables : {{ salesPlace.week.finishedRepairsUNR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Sans réparation : {{ salesPlace.week.finishedRepairsRSR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Non réparées : {{ salesPlace.week.finishedRepairsNTR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cog/>
                                                                    </span>
                                                                    SAV : {{ salesPlace.week.sav || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="this.isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Nb vente : {{ salesPlace.week.nbSale || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="this.isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-action-data-transfer/>
                                                                    </span>
                                                                    Echange  : {{ salesPlace.week.nbForfait || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-shopping-cart-1/>
                                                                    </span>Panier Moyen/Client HT :
                                                                    {{ $filters.decimal(salesPlace.week.averageSale) }}€
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-accounting-document/>
                                                                    </span>
                                                                    Nb remboursement : {{ salesPlace.week.nbRefund || 0 }}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>
                                                                    Mnt Remb. HT :
                                                                    {{ $filters.decimal(parseFloat(salesPlace.week.amountRefund)) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-charger/>
                                                                    </span>Nb accessoire : {{ salesPlace.week.nbAccessory || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>Mnt accessoire HT : {{ $filters.decimal(salesPlace.week.amountAccessory) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-action-warning/>
                                                                    </span>Nb pose de film : {{ salesPlace.week.nbHydrogel || 0 }}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>Pose de film HT : {{ $filters.decimal(salesPlace.week.amountHydrogel) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <!-- MOIS -->
                                                <div class="row mt-3" :class="this.isBmp ? 'bg-dark' : 'bg-green'">
                                                    <div class="col-lg-3 col-12 text-center text-white align-self-center" >
                                                        Chiffre d'affaires du mois
                                                    </div>
                                                    <div class="col-lg-5 col-12 text-center bg-grey-light pt-2">
                                                        <ul class='list-unstyled'>
                                                            <li><strong>CA HT: {{ $filters.decimal(salesPlace.month.CA) || '0,00' }}€</strong></li>
                                                            <li>Objectif: {{ $filters.decimal(salesPlace.month.goal) || '0,00' }}€</li>
                                                        </ul>
                                                        <Doughnut ref='monthGoal'
                                                                  :data='salesMonthGoalData'
                                                                  :options='chartGoalOptions'
                                                                  :style='myStylesGoal'
                                                                  :plugins='chartPlugins'/>
                                                        <ul class='list-unstyled'>
                                                            <li class='mb-1'><span v-if="raiseMonth && saleMonthNYear" :class="raiseMonthClass">{{ formatRaiseMonthValue }}</span></li>
                                                            <li> N-1 : {{ $filters.decimal(saleMonthNYear) || '' }}€</li>
                                                            <li>Moy. réseau : {{ $filters.decimal(averageNetworkMonth) }}€</li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-lg-4 col-12 bg-grey-light p-2 f-size-list">
                                                        <ul v-if='salesPlace' class="list-unstyled">
                                                            <li v-if="!isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-shopping-cart-1/>
                                                                    </span>Panier Moyen/Client HT :
                                                                    {{ $filters.decimal(salesPlace.month.averageSale) }}€
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-broken/>
                                                                    </span>
                                                                    Prises en charge : {{ salesPlace.month.initiatedRepairsALL }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-alarm-bell/>
                                                                    </span>
                                                                    En attente : {{ salesPlace.month.initiatedWaiting }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Livrées : {{ salesPlace.month.finishedRepairsRTN }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Réparées : {{ salesPlace.month.finishedRepairsRPR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Irréparables : {{ salesPlace.month.finishedRepairsUNR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Sans réparation : {{ salesPlace.month.finishedRepairsRSR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Non réparées : {{ salesPlace.month.finishedRepairsNTR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cog/>
                                                                    </span>
                                                                    SAV : {{ salesPlace.month.sav || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="this.isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Nb vente : {{ salesPlace.month.nbSale || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="this.isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-action-data-transfer/>
                                                                    </span>
                                                                    Echange  : {{ salesPlace.month.nbForfait || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-shopping-cart-1/>
                                                                    </span>Panier Moyen/Client HT :
                                                                    {{ $filters.decimal(salesPlace.month.averageSale) }}€
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-accounting-document/>
                                                                    </span>
                                                                    Nb remboursement : {{ salesPlace.month.nbRefund || 0 }}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>
                                                                    Mnt Remb. HT :
                                                                    {{ $filters.decimal(parseFloat(salesPlace.month.amountRefund)) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-charger/>
                                                                    </span>Nb accessoire : {{ salesPlace.month.nbAccessory || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>Mnt accessoire HT : {{ $filters.decimal(salesPlace.month.amountAccessory) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-action-warning/>
                                                                    </span>Nb pose de film : {{ salesPlace.month.nbHydrogel || 0 }}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>Pose de film HT : {{ $filters.decimal(salesPlace.month.amountHydrogel) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <!-- ANNEE -->
                                                <div class="row mt-3 d-flex" :class="this.isBmp ? 'bg-dark' : 'bg-green'">
                                                    <div class="col-lg-3 col-12 text-center text-white align-self-center" >
                                                        Chiffre d'affaires de l'année
                                                    </div>
                                                    <div class="col-lg-5 col-12 text-center bg-grey-light pt-2">
                                                        <ul class='list-unstyled'>
                                                            <li><strong>CA HT: {{ $filters.decimal(salesPlace.year.CA) || '0,00' }}€</strong></li>
                                                            <li>Objectif: {{ $filters.decimal(salesPlace.year.goal) || '0,00' }}€</li>
                                                        </ul>
                                                        <div>
                                                            <Doughnut ref='yearGoal'
                                                                      :data='salesYearGoalData'
                                                                      :options='chartGoalOptions'
                                                                      :style='myStylesGoal'
                                                                      :plugins='chartPlugins'/>
                                                        </div>
                                                        <ul class='list-unstyled'>
                                                            <li class='mb-1'><span v-if="raiseYear && saleYearNYear" :class="raiseYearClass">{{ formatRaiseYearValue }}</span></li>
                                                            <li> N-1 : {{ $filters.decimal(saleYearNYear) || '' }}€</li>
                                                            <li>Moy. réseau : {{ $filters.decimal(averageNetworkYear) }}€</li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-lg-4 col-12 bg-grey-light p-2 f-size-list">
                                                        <ul v-if='salesPlace' class="list-unstyled">
                                                            <li v-if="!isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-shopping-cart-1/>
                                                                    </span>Panier Moyen/Client HT :
                                                                    {{ $filters.decimal(salesPlace.year.averageSale) }}€
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-broken/>
                                                                    </span>
                                                                    Prises en charge : {{ salesPlace.year.initiatedRepairsALL }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-alarm-bell/>
                                                                    </span>
                                                                    En attente : {{ salesPlace.year.initiatedWaiting }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Livrées : {{ salesPlace.year.finishedRepairsRTN }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Réparées : {{ salesPlace.year.finishedRepairsRPR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Irréparables : {{ salesPlace.year.finishedRepairsUNR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Sans réparation : {{ salesPlace.year.finishedRepairsRSR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Non réparées : {{ salesPlace.year.finishedRepairsNTR }}
                                                                </div>
                                                            </li>
                                                            <li v-if='!this.isBmp'>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cog/>
                                                                    </span>
                                                                    SAV : {{ salesPlace.year.sav || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="this.isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-check-square/>
                                                                    </span>
                                                                    Nb vente : {{ salesPlace.year.nbSale || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="this.isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-action-data-transfer/>
                                                                    </span>
                                                                    Echange  : {{ salesPlace.year.nbForfait || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-shopping-cart-1/>
                                                                    </span>Panier Moyen/Client HT :
                                                                    {{ $filters.decimal(salesPlace.year.averageSale) }}€
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-accounting-document/>
                                                                    </span>
                                                                    Nb remboursement : {{ salesPlace.year.nbRefund || 0 }}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>
                                                                    Mnt Remb. HT :
                                                                    {{ $filters.decimal(parseFloat(salesPlace.year.amountRefund)) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-charger/>
                                                                    </span>Nb accessoire : {{ salesPlace.year.nbAccessory || 0 }}
                                                                </div>
                                                            </li>
                                                            <li v-if="isBmp">
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>Mnt accessoire HT : {{ $filters.decimal(salesPlace.year.amountAccessory) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-phone-action-warning/>
                                                                    </span>Nb pose de film : {{ salesPlace.year.nbHydrogel || 0 }}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="row align-items-center pl-2">
                                                                    <span class='d-inline-flex w-8 pr-2'>
                                                                        <svg-cash-payment-wallet/>
                                                                    </span>Pose de film HT : {{ $filters.decimal(salesPlace.year.amountHydrogel) || '0,00' }}€
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6 pl-lg-3 mt-lg-0 mt-3">
                                                <h5 style="text-align: center;  margin-bottom: 20px;">
                                                    <span class="p-2" :class="this.isBmp ? 'bg-orange-linear' : 'bg-green-linear'">RACHATS</span></h5>
                                                <div class="row justify-content-center mb-8">
                                                    <div class="col-lg-3 bg-grey-light text-center"
                                                         style="border-right: white solid 7px;">
                                                        Jour<br>
                                                        {{ salesPlace.day.nbPurchase || 0 }}
                                                        <Doughnut ref='dayPurchaseGoal'
                                                                  :data='salesDayPurchaseGoalData'
                                                                  :options='chartGoalPurchaseOptions'
                                                                  :plugins='chartPlugins'/>
                                                        {{ $filters.decimal(salesPlace.day.amountPurchase) || '0,00' }}€
                                                    </div>
                                                    <div class="col-lg-3 bg-grey-light text-center"
                                                         style="border-right: white solid 7px;">
                                                        Hebdo<br>
                                                        {{ salesPlace.week.nbPurchase || 0 }}
                                                        <Doughnut ref='weekPurchaseGoal'
                                                                  :data='salesWeekPurchaseGoalData'
                                                                  :options='chartGoalPurchaseOptions'
                                                                  :plugins='chartPlugins'/>
                                                        {{ $filters.decimal(salesPlace.week.amountPurchase) || '0,00' }}€
                                                    </div>
                                                    <div class="col-lg-3 bg-grey-light text-center"
                                                         style="border-right: white solid 7px;">
                                                        Mensuel<br>
                                                        {{ salesPlace.month.nbPurchase || 0 }}
                                                        <Doughnut ref='monthPurchaseGoal'
                                                                  :data='salesMonthPurchaseGoalData'
                                                                  :options='chartGoalPurchaseOptions'
                                                                  :plugins='chartPlugins'/>
                                                        {{ $filters.decimal(salesPlace.month.amountPurchase) || '0,00' }}€
                                                    </div>
                                                    <div class="col-lg-3 bg-grey-light text-center">
                                                        Annuel<br>
                                                        {{ salesPlace.year.nbPurchase || 0 }}
                                                        <Doughnut ref='yearPurchaseGoal'
                                                                  :data='salesYearPurchaseGoalData'
                                                                  :options='chartGoalPurchaseOptions'
                                                                  :plugins='chartPlugins'/>
                                                        {{ $filters.decimal(salesPlace.year.amountPurchase) || '0,00' }}€
                                                    </div>
                                                </div>
                                                <div class="row mb-8">
                                                    <div class='col-12'>
                                                        <h5 v-if='!this.isBmp' class="text-center mb-3">
                                                            <span class="p-2" :class="this.isBmp ? 'bg-orange-linear' : 'bg-green-linear'">SUIVI CA REPARATION</span></h5>
                                                        <h5 v-if='this.isBmp' class="text-center mb-3">
                                                            <span class="p-2" :class="this.isBmp ? 'bg-orange-linear' : 'bg-green-linear'">SUIVI CA VENTE</span></h5>
                                                        <div class="row">
                                                            <chartLine ref='yearRepair'
                                                                       :data='salesYearRepair'
                                                                       :options='chartYearOptions'
                                                                       :style='myStylesLine'/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row d-flex align-items-center">
                                                    <div class="col-12">
                                                        <h5 class="text-center mb-5">
                                                            <span class="p-2 d-none d-lg-inline" :class="this.isBmp ? 'bg-orange-linear' : 'bg-green-linear'">MOYEN DE PAIEMENT JOURNALIER (TTC)</span>
                                                            <span class="p-2 d-inline d-lg-none" :class="this.isBmp ? 'bg-orange-linear' : 'bg-green-linear'">MOYEN DE PAIEMENT</span>
                                                        </h5>
                                                        <div v-for='(salePayment, key) in salesDayPayment'
                                                             :key='key'>
                                                            <ul class="list-unstyled">
                                                                <li v-for='(payement, key2) in salePayment.payements'
                                                                    :key='key2'
                                                                    class='mb-2'>
                                                                    <div class="row align-items-center pl-2">
                                                                        <span class='d-inline-flex pr-2' v-if='payement.label === "ESPECES"'>
                                                                            <svg-cash-payment-bill/>
                                                                        </span>
                                                                        <span class='d-inline-flex pr-2' v-if='payement.label === "CARTE BANCAIRE"'>
                                                                            <svg-cards/>
                                                                        </span>
                                                                        <span class='d-inline-flex pr-2' v-if='payement.label === "VIREMENT BANCAIRE"'>
                                                                            <svg-cash-payment-wallet/>
                                                                        </span>
                                                                        <span class='d-inline-flex pr-2' v-if='payement.label === "ALMA"'>
                                                                            <svg-credit-card-give/>
                                                                        </span>
                                                                        {{ payement.label }} : {{ $filters.decimal(payement.amount) }}€
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useAuthStore } from '@/stores/auth.store';
import { useLoader } from '@/stores/loader';
import { DateTime } from 'luxon';
import Datepicker from 'vuejs3-datepicker';
import svgComponents from '../../_svg/svgComponents';
import grappLoader from '@/components/Loader.vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import grappMenu from '@/components/Menu.vue';
import { useMenu } from '@/stores/menu';

import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Line as chartLine, Doughnut, Pie } from 'vue-chartjs';
import notif from '@/components/Notif.vue';

ChartJS.register(...registerables);


const dateOpts = { weekday: 'long', month: 'long', day: 'numeric' };
const timeOpts = { hour: '2-digit', minute: '2-digit' };

export default defineComponent({
    name: 'DashBoard',
    components: { notif, grappLoader, Bar, Datepicker, chartLine, Doughnut, ...svgComponents, grappMenu },
    data() {
        return {
            date: new Date().toLocaleDateString('fr-FR', dateOpts),
            time: new Date()
                .toLocaleTimeString('fr-FR', timeOpts)
                .replace(':', 'h'),
            classEnv: null,
            groupSale: false,
            displayName: '',
            salesDate: DateTime.now().toHTTP(),
            salesPlace: { day: { CA: null }, week: { CA: null }, month: { CA: null }, year: { CA: null } },
            salesDay: [],
            salesGroup: {
                labels: [],
                datasets: []
            },
            salesGroupDetail: {
                labels: [],
                datasets: []
            },
            salesDayPayment: [],
            salesCurrentWeek: [],
            salesCurrentMonth: [],
            salesCurrentYear: [],
            salesLastYears: [],
            salesByPayment: [],
            salesDayData: {
                labels: [],
                datasets: []
            },
            salesDayGoalData: {
                labels: [],
                datasets: []
            },
            salesYearGoalData: {
                labels: [],
                datasets: [],
            },
            salesMonthGoalData: {
                labels: [],
                datasets: [],
            },
            salesWeekGoalData: {
                labels: [],
                datasets: [],
            },
            salesYearPurchaseGoalData: {
                labels: [],
                datasets: [],
            },
            salesMonthPurchaseGoalData: {
                labels: [],
                datasets: [],
            },
            salesWeekPurchaseGoalData: {
                labels: [],
                datasets: [],
            },
            salesDayPurchaseGoalData: {
                labels: [],
                datasets: [],
            },
            salesYearRepair: {
                labels: [],
                datasets: [],
            },
            salesYearPurchase: {
                labels: [],
                datasets: [],
            },
            loaded: false,
            delayed: false,
            chartPlugins: [ChartDataLabels],
            chartGroupOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        grid: {
                            display: false
                        }
                    }
                },
                layout: {
                    padding: {
                        top: 30
                    }
                },
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        color: 'Grey',
                        align: 'end',
                        font: {
                            weight: 'bold',
                            size: 14
                        },
                        display: true,
                        clip: false,
                        clamp: true,
                        formatter: (value, _context) => {
                            return `${this.$filters.decimal(value)}€`;
                        }
                    },
                    legend: {
                        display: false,
                    },
                },
            },
            chartDayOptions: {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    onComplete: () => {
                        this.delayed = true;
                    },
                    delay: (context) => {
                        let delay = 0;
                        if (context.type === 'data' && context.mode === 'default' && !this.delayed) {
                            delay = context.dataIndex * 50 + context.datasetIndex * 100;
                        }
                        return delay;
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    layout: {
                        padding: {
                            top: 0,
                            bottom: 0
                        }
                    },
                    datalabels: {
                        color: 'grey',
                        anchor: 'end',
                        align: 'end',
                        display: true,
                        rotation: 0,
                        offset: 0,
                        clip: false,
                        clamp: true,
                        formatter: (value, _context) => {
                            return `${this.$filters.decimal(value)}€`;
                        }
                    },
                    legend: {
                        display: false
                    },
                },
            },
            chartGoalOptions: {
                rotation: -90,
                circumference: 180,
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    datalabels: {
                        color: '#ffffff',
                        anchor: 'center',
                        align: 'center',
                        display: (context) => {
                            let dataset = context.dataset;
                            let value = dataset.data[context.dataIndex];
                            return context.dataIndex === 0  && value > 0;
                        },
                        rotation: 0,
                        clip: true,
                        clamp: false,
                        formatter: (value, _context) => {
                            return `${value}%`;
                        }
                    },
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                layout: {
                    padding: 0
                }
            },
            chartGoalPurchaseOptions: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    datalabels: {
                        color: '#ffffff',
                        anchor: 'center',
                        align: 'center',
                        rotation: 0,
                        clip: true,
                        clamp: false,
                        formatter: (value, _context) => {
                            return `${value}%`;
                        },
                        display: (context) => {
                            let dataset = context.dataset;
                            let value = dataset.data[context.dataIndex];
                            return context.dataIndex === 0 && value > 0;
                        }
                    },
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                layout: {
                    padding:  20
                }
            },
            chartYearOptions: {
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        pointBorderWidth: 5
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    }
                }
            },
            averageNetworkDay: 0,
            averageNetworkWeek: 0,
            averageNetworkMonth: 0,
            averageNetworkYear: 0,
            saleDayNYear: 0,
            salesDayNYear: [],
            raiseDay: 0,
            saleWeekNYear: 0,
            salesWeekNYear: [],
            raiseWeek: 0,
            saleMonthNYear: 0,
            salesMonthNYear: [],
            raiseMonth: 0,
            saleYearNYear: 0,
            salesYearNYear: [],
            salesLastN1Years: [],
            salesLastN2Years: [],
            goalsDay: [],
            goalsWeek: [],
            goalsMonth: [],
            goalsYear: [],
            raiseYear: 0,
            totalCr: 0,
            totalBmp: 0,
            totalCrFr: 0,
            totalBmpFr: 0,
            totalCrPr: 0,
            totalBmpPr: 0,
            selectedDay: DateTime.now().minus({ days: 1 }).toHTTP(),
            bgGreen: 'rgb(113, 147, 24)',
            bgOrange: 'rgb(244, 174, 29)',
            places: [],
            allPlaces: [],
            place: null,
            isBmp: false,
            sign: null,
            oneSign: false,
            type: 'day',
            lecture: false,
            numberGroup: false,
            version: process.env.VUE_APP_VERSION,
        };
    },
    created() {
        if (!this.authStore.getUser || (this.authStore.getUser && !this.authStore.getUser.lastName)) this.logout();
        setInterval(() => {
            this.date = new Date().toLocaleDateString('fr-FR', dateOpts);
            this.time = new Date()
                .toLocaleTimeString('fr-FR', timeOpts)
                .replace(':', 'h');
        }, 5000);
    },
    computed: {
        myStyles() {
            return {
                height: '800px',
                position: 'relative',
            };
        },
        myStylesLine() {
            return {
                height: '400px',
                position: 'relative',
            };
        },
        myStylesGoal() {
            return {
                margin: '-60px auto',
                'max-height': '300px',
                position: 'relative',
                'padding-left': '0',
                'padding-right': '0'
            };
        },
        raiseDayClass() {
            return this.raiseDay > 0 ? 'raise-item-positive' : 'raise-item-negative';
        },
        formatRaiseDayValue() {
            return this.raiseDay > 0
                ? `▲ ${this.$filters.decimal(this.raiseDay)}%`
                : `▼ ${this.$filters.decimal(this.raiseDay)}%`;
        },
        raiseWeekClass() {
            return this.raiseWeek > 0 ? 'raise-item-positive' : 'raise-item-negative';
        },
        formatRaiseWeekValue() {
            return this.raiseWeek > 0
                ? `▲ ${this.$filters.decimal(this.raiseWeek)}%`
                : `▼ ${this.$filters.decimal(this.raiseWeek)}%`;
        },
        raiseMonthClass() {
            return this.raiseMonth > 0 ? 'raise-item-positive' : 'raise-item-negative';
        },
        formatRaiseMonthValue() {
            return this.raiseMonth > 0
                ? `▲ ${this.$filters.decimal(this.raiseMonth)}%`
                : `▼ ${this.$filters.decimal(this.raiseMonth)}%`;
        },
        raiseYearClass() {
            return this.raiseYear > 0 ? 'raise-item-positive' : 'raise-item-negative';
        },
        formatRaiseYearValue() {
            return this.raiseYear > 0
                ? `▲ ${this.$filters.decimal(this.raiseYear)}%`
                : `▼ ${this.$filters.decimal(this.raiseYear)}%`;
        },
    },
    async mounted() {
        this.getEnv();
        await this.getPlaces();
        await this.filterSign();
        this.displayName = this.authStore.getUser ? this.authStore.getUser.firstName ? this.authStore.getUser.firstName + ' ' + this.authStore.getUser.lastName
            : this.authStore.getUser.login : null;
        this.lecture = await this.authStore.moduleRight('Tableau de bord', 'Lecture');
        this.numberGroup = await this.authStore.moduleRight('Chiffres groupe', 'Lecture');
    },
    methods: {
        logout() {
            return this.authStore.logout();
        },
        getEnv() {
            if (document.location.href.search('localhost') !== -1) {
                this.classEnv = 'is-dev';
            } else if (document.location.href.search('ready2dev') !== -1) {
                this.classEnv = 'is-preprod';
            } else {
                this.classEnv = 'is-prod';
            }
        },
        async getSales() {
            let chartInstance;
            try {
                this.loader.show('load');
                this.salesDate = DateTime.fromMillis(Date.parse(this.salesDate)).toFormat('yyyy-MM-dd');
                this.selectedDay = DateTime.fromISO(this.salesDate).toFormat('dd/MM/yyyy');
                let res = await this.axios.get(
                    `${window.location.origin}/api/crd/sales/${this.salesDate}/${this.type}`,
                );
                this.salesDay = res.data.sales;
                this.goalsDay = res.data.goals;
                this.salesDayNYear = res.data.salesLastYear;
                this.salesByPayment = res.data.salesByPayment;
                this.salesDayPayment = res.data.salesByPayment;
                this.salesDayData.labels = [];
                this.salesDayData.datasets = [];
                this.salesGroup.labels = [];
                this.salesGroup.datasets = [];
                this.salesGroupDetail.labels = [];
                this.salesGroupDetail.datasets = [];
                this.totalCr = res.data.totalCr;
                this.totalBmp = res.data.totalBmp;
                this.totalCrPr = res.data.totalCrPr;
                this.totalCrFr = res.data.totalCrFr;
                this.totalBmpPr = res.data.totalBmpPr;
                this.totalBmpFr = res.data.totalBmpFr;
                if (this.salesDay.length > 0) {
                    let CAs = [];
                    this.salesDay.forEach(data => {
                        if (data.name.indexOf(this.sign) !== -1 || !this.sign) {
                            CAs.push(data.CA || 0);
                            this.salesDayData.labels.push(data.name);
                        }
                    });
                    this.salesDayData.datasets.push(
                        {
                            data: CAs,
                            //barThickness: 10,
                            label: 'CA HT',
                            borderColor: 'rgb(159,198,59)',
                            backgroundColor: 'rgb(159,198,59,0.1)',
                            borderWidth: 2,
                        }
                    );
                    if(!this.sign) {
                        this.salesGroup.datasets.push(
                            {
                                data: [this.totalCr, this.totalBmp],
                                //barThickness: 10,
                                label: 'CA HT',
                                backgroundColor: ['#41B883', '#E46651'],
                            }
                        );
                        this.salesGroup.labels.push('C&R', 'BMP');
                        this.salesGroupDetail.datasets.push(
                            {
                                data: [this.totalCrPr, this.totalCrFr, this.totalBmpPr, this.totalBmpFr],
                                label: 'CA HT',
                                backgroundColor: ['#41B883','#41b8ae', '#E46651', '#e48951'],
                            }
                        );
                        this.salesGroupDetail.labels.push('C&R en propre', 'C&R franchisé', 'BMP en propre', 'BMP franchisé');
                    }else {
                        if(this.sign === 'C&R') {
                            this.salesGroup.datasets.push(
                                {
                                    data: [this.totalCr],
                                    //barThickness: 10,
                                    label: 'CA HT',
                                    backgroundColor: ['#41B883'],
                                }
                            );
                            this.salesGroup.labels.push('C&R');
                            this.salesGroupDetail.datasets.push(
                                {
                                    data: [this.totalCrPr, this.totalCrFr],
                                    label: 'CA HT',
                                    backgroundColor: ['#41B883','#41b8ae'],
                                }
                            );
                            this.salesGroupDetail.labels.push('C&R en propre', 'C&R franchisé');
                        }else {
                            this.salesGroup.datasets.push(
                                {
                                    data: [this.totalBmp],
                                    //barThickness: 10,
                                    label: 'CA HT',
                                    backgroundColor: ['#E46651'],
                                }
                            );
                            this.salesGroup.labels.push('BMP');
                            this.salesGroupDetail.datasets.push(
                                {
                                    data: [this.totalBmpPr, this.totalBmpFr],
                                    label: 'CA HT',
                                    backgroundColor: ['#E46651', '#e48951'],
                                }
                            );
                            this.salesGroupDetail.labels.push('BMP en propre', 'BMP franchisé');
                        }
                    }
                }
                this.loaded = true;
                if(this.$refs.chartDay) {
                    chartInstance = this.$refs.chartDay.chart;
                    chartInstance.data = this.salesDayData;
                    chartInstance.options.plugins.title.text = 'Chiffre d\'affaire du ' + this.salesDate;
                    chartInstance.update();
                }
                if(this.$refs.group) {
                    chartInstance = this.$refs.group.chart;
                    chartInstance.data = this.salesGroup;
                    chartInstance.options.plugins.title.text = 'Chiffre d\'affaire des réseaux';
                    chartInstance.update();
                }
                if(this.$refs.groupDetail) {
                    chartInstance = this.$refs.groupDetail.chart;
                    chartInstance.data = this.salesGroupDetail;
                    chartInstance.options.plugins.title.text = 'Chiffre d\'affaire des réseaux par type';
                    chartInstance.update();
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.loader.hide('load');
            }
        },
        async filterSale() {
            let res;
            this.salesDayData.datasets = [];
            this.salesDayData.labels = [];
            try {
                this.loader.show('load');
                if (this.place) {
                    this.isBmp = ['3', '4', '6'].indexOf(this.place.placeTypeId) !== -1;
                    res = await this.axios.get(
                        `${window.location.origin}/api/crd/sales/${this.salesDate}/week/${this.place.placeId}`,
                    );
                    this.salesCurrentWeek = res.data.sales;
                    this.salesWeekNYear = res.data.salesLastYear;
                    this.averageNetworkWeek = res.data.average;
                    this.goalsWeek = res.data.goals;
                    res = await this.axios.get(
                        `${window.location.origin}/api/crd/sales/${this.salesDate}/month/${this.place.placeId}`,
                    );
                    this.salesCurrentMonth = res.data.sales;
                    this.salesMonthNYear = res.data.salesLastYear;
                    this.averageNetworkMonth = res.data.average;
                    this.goalsMonth = res.data.goals;
                    res = await this.axios.get(
                        `${window.location.origin}/api/crd/sales/${this.salesDate}/year/${this.place.placeId}`,
                    );
                    this.salesCurrentYear = res.data.sales;
                    this.salesYearNYear = res.data.salesLastYear;
                    this.averageNetworkYear = res.data.average;
                    this.goalsYear = res.data.goals;
                    res = await this.axios.get(
                        `${window.location.origin}/api/crd/sales/year/${this.salesDate}/${this.place.placeId}`,
                    );
                    this.salesLastYears = res.data;
                    let salesN1Date = DateTime.fromISO(this.salesDate).minus({ year: 1 }).toISODate();
                    res = await this.axios.get(
                        `${window.location.origin}/api/crd/sales/year/${salesN1Date}/${this.place.placeId}`,
                    );
                    this.salesLastN1Years = res.data;
                    let salesN2Date = DateTime.fromISO(this.salesDate).minus({ year: 2 }).toISODate();
                    res = await this.axios.get(
                        `${window.location.origin}/api/crd/sales/year/${salesN2Date}/${this.place.placeId}`,
                    );
                    this.salesLastN2Years = res.data;
                    this.salesDayPayment = this.salesByPayment.reduce((curr, sp) => {
                        if (sp.idLieu === this.place.placeId) {
                            curr.push(sp);
                        }
                        return curr;
                    }, []);
                    this.saleDayNYear = this.salesDayNYear.reduce((curr, s) => {
                        if(s.idLieu === this.place.placeId)
                            curr += s.CA;
                        return curr;
                    },0);
                    this.averageNetworkDay = 0;
                    if (this.salesDay.length > 0) {
                        let CrDay = this.salesDay.reduce((curr, sale) => {
                            if(sale.name.indexOf('C&R') !== -1)
                                curr.push(sale);
                            return curr;
                        },[]);
                        let BmpDay = this.salesDay.reduce((curr, sale) => {
                            if(sale.name.indexOf('BMP') !== -1)
                                curr.push(sale);
                            return curr;
                        },[]);
                        this.salesDay.forEach(data => {
                            if (data.idLieu === this.place.placeId) {
                                this.salesPlace.day = data;
                            }
                        });
                        let networkDayCR = CrDay.reduce((curr, sale) => {
                            curr += sale.CA;
                            return curr;
                        }, 0);
                        let networkDayBMP = BmpDay.reduce((curr, sale) => {
                            curr += sale.CA;
                            return curr;
                        }, 0);
                        this.averageNetworkDay = this.isBmp ? (networkDayBMP / BmpDay.length) : (networkDayCR / CrDay.length);
                        if (!this.salesDay.find(sale => sale.idLieu === this.place.placeId)) {
                            this.salesPlace.day.CA = 0;
                            this.salesPlace.day.averageRepair = 0;
                            this.salesPlace.day.averageSale = 0;
                            this.salesPlace.day.amountPurchase = 0;
                            this.salesPlace.day.amountRefund = 0;
                            this.salesPlace.day.nbPurchase = 0;
                            this.salesPlace.day.nbRefund = 0;
                            this.salesPlace.day.sav = 0;
                            this.salesPlace.day.initiatedRepairs = 0;
                            this.salesPlace.day.finishedRepairs = 0;
                        }
                        this.raiseDay = (this.salesPlace.day.CA - this.saleDayNYear) / this.saleDayNYear * 100;
                    } else {
                        this.salesPlace.day.CA = 0;
                        this.salesPlace.week.CA = 0;
                        this.salesPlace.month.CA = 0;
                        this.salesPlace.year.CA = 0;
                        this.raiseDay = (this.salesPlace.day.CA - this.saleDayNYear) / this.saleDayNYear * 100;
                    }
                    this.salesPlace.day.goal = this.goalsDay.reduce((curr, goal) => {
                        if(goal.placeId === this.place.placeId)
                            curr += goal.goal;
                        return curr;
                    }, 0);
                    this.salesDayGoalData.labels = [];
                    this.salesDayGoalData.datasets = [];
                    if(this.salesPlace.day.goal) {
                        this.salesDayGoalData.labels.push('Objectif atteint', 'Restant');
                        this.salesDayGoalData.datasets.push(
                            {
                                data: [Math.round(this.salesPlace.day.CA * 100 / this.salesPlace.day.goal),
                                    (100 - Math.round(this.salesPlace.day.CA * 100 / this.salesPlace.day.goal)) < 0
                                        ? 0 : 100 - Math.round(this.salesPlace.day.CA * 100 / this.salesPlace.day.goal)],
                                // borderColor: this.isBmp ? this.bgOrange : this.bgGreen,
                                backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'],
                                // borderWidth: 0,
                            }
                        );
                    }else {
                        this.salesDayGoalData.labels = ['Objectif atteint', 'Restant'];
                        this.salesDayGoalData.datasets = [{ data: [0,100], backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'] }];
                    }
                    let chartInstance = this.$refs.dayGoal.chart;
                    chartInstance.data = this.salesDayGoalData;
                    chartInstance.update();
                    let week = this.salesCurrentWeek.reduce((curr, sale) => {
                        if (sale.idLieu === this.place.placeId)
                            curr = sale;
                        return curr;
                    }, {});
                    week.goal = this.goalsWeek.reduce((curr, goal) => {
                        if (goal.placeId === this.place.placeId)
                            curr += goal.goal;
                        return curr;
                    }, 0);
                    let CrWeek = this.salesCurrentWeek.reduce((curr, sale) => {
                        if(sale.name.indexOf('C&R') !== -1)
                            curr.push(sale);
                        return curr;
                    },[]);
                    let BmpWeek = this.salesCurrentWeek.reduce((curr, sale) => {
                        if(sale.name.indexOf('BMP') !== -1)
                            curr.push(sale);
                        return curr;
                    },[]);
                    this.averageNetworkWeek = this.isBmp ? this.averageNetworkWeek.bmp : this.averageNetworkWeek.cr;
                    let month = this.salesCurrentMonth.reduce((curr, sale) => {
                        if (sale.idLieu === this.place.placeId)
                            curr = sale;
                        return curr;
                    }, {});
                    month.goal = this.goalsMonth.reduce((curr, goal) => {
                        if (goal.placeId === this.place.placeId)
                            curr += goal.goal;
                        return curr;
                    }, 0);
                    this.saleWeekNYear = this.salesWeekNYear.reduce((curr, s)=>{
                        if(s.idLieu === this.place.placeId)
                            curr += s.CA;
                        return curr;
                    },0);
                    this.averageNetworkMonth = this.isBmp ? this.averageNetworkMonth.bmp : this.averageNetworkMonth.cr;
                    let year = this.salesCurrentYear.reduce((curr, sale) => {
                        if (sale.idLieu === this.place.placeId)
                            curr = sale;
                        return curr;
                    }, {});
                    year.goal = this.goalsYear.reduce((curr, goal) => {
                        if (goal.placeId === this.place.placeId)
                            curr += goal.goal;
                        return curr;
                    }, 0);
                    this.saleMonthNYear = this.salesMonthNYear.reduce((curr, s)=>{
                        if(s.idLieu === this.place.placeId)
                            curr += s.CA;
                        return curr;
                    },0);
                    this.averageNetworkYear = this.isBmp ? this.averageNetworkYear.bmp : this.averageNetworkYear.cr;
                    this.saleYearNYear = this.salesYearNYear.reduce((curr, s)=>{
                        if(s.idLieu === this.place.placeId)
                            curr += s.CA;
                        return curr;
                    },0);
                    let CAs = [];
                    let N1CAs = [];
                    let N2CAs = [];
                    this.salesYearRepair.datasets = [];
                    this.salesYearRepair.labels = ['janvier','février','mars','avril','mai','juin','juillet','août','septembre','octobre','novembre','décembre'];
                    this.salesLastN1Years.map(month => {
                        Object.keys(month).map(key => {
                            month[key] = month[key].reduce((curr, sale) => {
                                if (sale.idLieu === this.place.placeId)
                                    curr = sale;
                                return curr;
                            }, []);
                            // this.salesYearRepair.labels.push(key.split(' ')[0]);
                            let CA = month[key].CA ? month[key].CA : null;
                            N1CAs.push(CA);
                        });
                    });
                    this.salesLastN2Years.map(month => {
                        Object.keys(month).map(key => {
                            month[key] = month[key].reduce((curr, sale) => {
                                if (sale.idLieu === this.place.placeId)
                                    curr = sale;
                                return curr;
                            }, []);
                            let CA = month[key].CA ? month[key].CA : null;
                            N2CAs.push(CA);
                        });
                    });
                    this.salesLastYears.map(month => {
                        Object.keys(month).map(key => {
                            month[key] = month[key].reduce((curr, sale) => {
                                if (sale.idLieu === this.place.placeId)
                                    curr = sale;
                                return curr;
                            }, []);
                            let CA = month[key].CA ? month[key].CA : null;
                            CAs.push(CA);
                        });
                    });
                    let currentYear = DateTime.fromISO(this.salesDate).get('year');
                    let yearN1 = currentYear - 1;
                    let yearN2 = currentYear - 2;
                    this.salesYearRepair.datasets.push({
                        data: N2CAs,
                        label: yearN2,
                        borderColor: '#1c15f6',
                        backgroundColor: '#1c15f6'
                        // fill: true,
                        // tension: 0.3,
                    },{
                        data: N1CAs,
                        label: yearN1,
                        borderColor: '#f61515',
                        backgroundColor: '#f61515'
                        // fill: true,
                        // tension: 0.3,
                    },{
                        data: CAs,
                        label: currentYear,
                        borderColor: this.isBmp ? this.bgOrange : this.bgGreen,
                        backgroundColor: this.isBmp ? this.bgOrange : this.bgGreen,
                    });
                    chartInstance = this.$refs.yearRepair.chart;
                    chartInstance.data = this.salesYearRepair;
                    chartInstance.update();
                    this.salesPlace.week = week;
                    this.salesPlace.month = month;
                    this.salesPlace.year = year;
                    this.raiseWeek = (this.salesPlace.week.CA - this.saleWeekNYear) / this.saleWeekNYear * 100;
                    this.raiseMonth = (this.salesPlace.month.CA - this.saleMonthNYear) / this.saleMonthNYear * 100;
                    this.raiseYear = (this.salesPlace.year.CA - this.saleYearNYear) / this.saleYearNYear * 100;
                    this.salesWeekGoalData.labels = [];
                    this.salesWeekGoalData.datasets = [];
                    if (this.salesPlace.week.CA && this.salesPlace.week.goal) {
                        this.salesWeekGoalData.labels.push('Objectif atteint', 'Restant');
                        this.salesWeekGoalData.datasets.push(
                            {
                                data: [ Math.round(this.salesPlace.week.CA * 100 / this.salesPlace.week.goal),
                                    100 - Math.round(this.salesPlace.week.CA * 100 / this.salesPlace.week.goal) < 0
                                        ? 0 : 100 - Math.round(this.salesPlace.week.CA * 100 / this.salesPlace.week.goal) ],
                                // borderColor: this.isBmp ? this.bgOrange : this.bgGreen,
                                backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'],
                                // borderWidth: 2,
                            }
                        );
                    }else{
                        this.salesWeekGoalData.labels.push('Objectif atteint', 'Restant');
                        this.salesWeekGoalData.datasets.push(
                            {
                                data: [ 0,100 ],
                                // borderColor: this.isBmp ? this.bgOrange : this.bgGreen,
                                backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'],
                                // borderWidth: 2,
                            }
                        );
                    }
                    chartInstance = this.$refs.weekGoal.chart;
                    chartInstance.data = this.salesWeekGoalData;
                    chartInstance.update();
                    this.salesMonthGoalData.labels = [];
                    this.salesMonthGoalData.datasets = [];
                    if (this.salesPlace.month.CA && this.salesPlace.month.goal) {
                        this.salesMonthGoalData.labels.push('Objectif atteint', 'Restant');
                        this.salesMonthGoalData.datasets.push(
                            {
                                data: [ Math.round(this.salesPlace.month.CA * 100 / this.salesPlace.month.goal),
                                    100 - Math.round(this.salesPlace.month.CA * 100 / this.salesPlace.month.goal) < 0
                                        ? 0 : 100 - Math.round(this.salesPlace.month.CA * 100 / this.salesPlace.month.goal) ],
                                // borderColor: this.isBmp ? this.bgOrange : this.bgGreen,
                                backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'],
                                // borderWidth: 2,
                            }
                        );
                    }else {
                        this.salesMonthGoalData.labels = ['Objectif atteint', 'Restant'];
                        this.salesMonthGoalData.datasets = [ { data: [0,100], backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'], } ];
                    }
                    chartInstance = this.$refs.monthGoal.chart;
                    chartInstance.data = this.salesMonthGoalData;
                    chartInstance.update();
                    this.salesYearGoalData.labels = [];
                    this.salesYearGoalData.datasets = [];
                    if (this.salesPlace.year.CA && this.salesPlace.year.goal) {
                        this.salesYearGoalData.labels.push('Objectif atteint', 'Restant');
                        this.salesYearGoalData.datasets.push(
                            {
                                data: [ Math.round(this.salesPlace.year.CA * 100 / this.salesPlace.year.goal),
                                    100 - Math.round(this.salesPlace.year.CA * 100 / this.salesPlace.year.goal) < 0
                                        ? 0 : 100 - Math.round(this.salesPlace.year.CA * 100 / this.salesPlace.year.goal) ],
                                // borderColor: this.isBmp ? this.bgOrange : this.bgGreen,
                                backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'],
                                // borderWidth: 2,
                            }
                        );
                    }else {
                        this.salesYearGoalData.labels = ['Objectif atteint', 'Restant'];
                        this.salesYearGoalData.datasets = [ { data: [0,100], backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'], } ];
                    }
                    chartInstance = this.$refs.yearGoal.chart;
                    chartInstance.data = this.salesYearGoalData;
                    chartInstance.update();
                    this.salesDayPurchaseGoalData.labels = [];
                    this.salesDayPurchaseGoalData.datasets = [];
                    if(this.salesPlace.day.amountPurchase > 0 && this.salesPlace.week.purchaseGoal) {
                        this.salesDayPurchaseGoalData.labels.push('Objectif atteint', 'Restant');
                        this.salesDayPurchaseGoalData.datasets.push(
                            {
                                data: [100,0],
                                backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'],
                            }
                        );
                    }else {
                        this.salesDayPurchaseGoalData.labels.push('Objectif atteint', 'Restant');
                        this.salesDayPurchaseGoalData.datasets.push(
                            {
                                data: [0,100],
                                backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'],
                            }
                        );
                    }
                    chartInstance = this.$refs.dayPurchaseGoal.chart;
                    chartInstance.data = this.salesDayPurchaseGoalData;
                    chartInstance.update();
                    this.salesWeekPurchaseGoalData.labels = [];
                    this.salesWeekPurchaseGoalData.datasets = [];
                    if(this.salesPlace.week.amountPurchase && this.salesPlace.week.purchaseGoal) {
                        this.salesWeekPurchaseGoalData.labels.push('Objectif atteint', 'Restant');
                        this.salesWeekPurchaseGoalData.datasets.push(
                            {
                                data: [ Math.round(this.salesPlace.week.amountPurchase * 100 / this.salesPlace.week.purchaseGoal),
                                    100 - Math.round(this.salesPlace.week.amountPurchase * 100 / this.salesPlace.week.purchaseGoal) < 0
                                        ? 0 : 100 - Math.round(this.salesPlace.week.amountPurchase * 100 / this.salesPlace.week.purchaseGoal) ],
                                // borderColor: this.isBmp ? this.bgOrange : this.bgGreen,
                                backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'],
                                // borderWidth: 2,
                            }
                        );
                    }else {
                        this.salesWeekPurchaseGoalData.labels = ['Objectif atteint', 'Restant'];
                        this.salesWeekPurchaseGoalData.datasets = [ { data: [0,100], backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'], } ];
                    }
                    chartInstance = this.$refs.weekPurchaseGoal.chart;
                    chartInstance.data = this.salesWeekPurchaseGoalData;
                    chartInstance.update();
                    this.salesMonthPurchaseGoalData.labels = [];
                    this.salesMonthPurchaseGoalData.datasets = [];
                    if(this.salesPlace.month.amountPurchase && this.salesPlace.month.purchaseGoal) {
                        this.salesMonthPurchaseGoalData.labels.push('Objectif atteint', 'Restant');
                        this.salesMonthPurchaseGoalData.datasets.push(
                            {
                                data: [ Math.round(this.salesPlace.month.amountPurchase * 100 / this.salesPlace.month.purchaseGoal),
                                    100 - Math.round(this.salesPlace.month.amountPurchase * 100 / this.salesPlace.month.purchaseGoal) < 0
                                        ? 0 : 100 - Math.round(this.salesPlace.month.amountPurchase * 100 / this.salesPlace.month.purchaseGoal) ],
                                // borderColor: this.isBmp ? this.bgOrange : this.bgGreen,
                                backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'],
                                // borderWidth: 2,
                            }
                        );
                    }else {
                        this.salesMonthPurchaseGoalData.labels = ['Objectif atteint', 'Restant'];
                        this.salesMonthPurchaseGoalData.datasets = [ { data: [0,100], backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'], } ];
                    }
                    chartInstance = this.$refs.monthPurchaseGoal.chart;
                    chartInstance.data = this.salesMonthPurchaseGoalData;
                    chartInstance.update();
                    this.salesYearPurchaseGoalData.labels = [];
                    this.salesYearPurchaseGoalData.datasets = [];
                    if(this.salesPlace.year.amountPurchase && this.salesPlace.year.purchaseGoal) {
                        this.salesYearPurchaseGoalData.labels.push('Objectif atteint', 'Restant');
                        this.salesYearPurchaseGoalData.datasets.push(
                            {
                                data: [ Math.round(this.salesPlace.year.amountPurchase * 100 / this.salesPlace.year.purchaseGoal),
                                    100 - Math.round(this.salesPlace.year.amountPurchase * 100 / this.salesPlace.year.purchaseGoal) < 0
                                        ? 0 : 100 - Math.round(this.salesPlace.year.amountPurchase * 100 / this.salesPlace.year.purchaseGoal) ],
                                // borderColor: this.isBmp ? this.bgOrange : this.bgGreen,
                                backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'],
                                // borderWidth: 2,
                            }
                        );
                    }else {
                        this.salesYearPurchaseGoalData.labels = ['Objectif atteint', 'Restant'];
                        this.salesYearPurchaseGoalData.datasets = [ { data: [0,100], backgroundColor: [this.isBmp ? this.bgOrange : this.bgGreen, '#C6C2C1'], } ];
                    }
                    chartInstance = this.$refs.yearPurchaseGoal.chart;
                    chartInstance.data = this.salesYearPurchaseGoalData;
                    chartInstance.update();
                } else {
                    this.salesDayPayment = this.salesByPayment;
                    if (this.salesDay.length > 0) {
                        let CAs = [];
                        this.salesDay.forEach(data => {
                            if (data.name.indexOf(this.sign) !== -1 || !this.sign) {
                                CAs.push(data.CA);
                                this.salesDayData.labels.push(data.name);
                            }
                        });
                        this.salesDayData.datasets.push({
                            data: CAs,
                            label: 'CA HT',
                            borderColor: 'rgb(159,198,59)',
                            backgroundColor: 'rgb(159,198,59,0.1)',
                            borderWidth: 2,
                        });
                    }
                    this.salesPlace = { day: { CA: null }, week: { CA: null }, month: { CA: null }, year: { CA: null } };
                    this.salesCurrentMonth = [];
                    this.salesCurrentWeek = [];
                    this.salesCurrentYear = [];
                    let chartInstance = this.$refs.chartDay.chart;
                    chartInstance.data = this.salesDayData;
                    chartInstance.options.plugins.title.text = 'Chiffre d\'affaire du ' + this.salesDate;
                    chartInstance.update();
                }
            } catch (err) {
                console.error(err);
            } finally {
                this.loader.hide('load');
            }

        },
        async filterSign() {
            if (this.sign === 'BMP') {
                this.places = this.allPlaces.reduce((curr, place) => {
                    if (['3', '4', '6'].indexOf(place.placeTypeId) !== -1)
                        curr.push(place);
                    return curr;
                }, []);
            }
            if (this.sign === 'C&R') {
                this.places = this.allPlaces.reduce((curr, place) => {
                    if (['3', '4', '6'].indexOf(place.placeTypeId) === -1)
                        curr.push(place);
                    return curr;
                }, []);
            }
            if (this.sign === null) {
                this.places = this.allPlaces;
            }
            this.place = null;
            await this.getSales();
        },
        async dayAfter() {
            this.salesDate = DateTime.fromMillis(Date.parse(this.salesDate)).plus({ days: 1 }).toFormat('yyyy-MM-dd');
            // await this.getPlaces();
            await this.getSales();
            if(this.place)
                await this.filterSale();
        },
        async dayBefore() {
            this.salesDate = DateTime.fromMillis(Date.parse(this.salesDate)).minus({ days: 1 }).toFormat('yyyy-MM-dd');
            // await this.getPlaces();
            await this.getSales();
            if(this.place)
                await this.filterSale();
        },
        async selectDate() {
            // await this.getPlaces();
            await this.getSales();
            if(this.place)
                await this.filterSale();
        },
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data.reduce((curr, place) => {
                    if (['2', '6', '999'].indexOf(place.placeId) === -1 && place.status) {
                        curr.push(place);
                    }
                    return curr;
                }, []);
                if (this.authStore.getUser.places.length === 1 && !this.authStore.getUser.networks.length) {
                    this.sign = this.authStore.getUser.places[0].name.indexOf('BMP') !== -1 ? 'BMP' : 'C&R';
                    this.oneSign = true;
                } else {
                    let BMP = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('BMP') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    let CR = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('C&R') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    if((BMP.length > 0 && CR.length > 0) || this.authStore.getUser.networks.length === 2) {
                        this.sign = null;
                    }else if (BMP.length > 0 || this.authStore.getUser.networks.find(network => network.shortName === 'BMP')) {
                        this.sign = 'BMP';
                        this.oneSign = true;
                    }else if(CR.length > 0 || this.authStore.getUser.networks.find(network => network.shortName === 'C&R')) {
                        this.sign = 'C&R';
                        this.oneSign = true;
                    }
                }
                this.allPlaces = JSON.parse(JSON.stringify(this.places));
            } catch (err) {
                console.log(err);
            }
        },
    },
    setup() {
        return {
            authStore: useAuthStore(),
            loader: useLoader(),
            menu: useMenu()
        };
    },

});
</script>

<style scoped>
img {
    max-width: 155px;
    height: auto;
}

.wrapperClass.vuejs3-datepicker__inputvalue {
    padding: 6px 12px;
    border: 1px solid #666666 !important;
}

.w-8 {
    width: 8% !important;
}

.sidebar--active {
    transition: all .3s ease-in-out 0s !important;
    transform: translateX(0) !important;
    opacity: 1 !important;
}

.menus-nav-layout #sidebar {
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 72px;
    transform: translateX(-150%);
    transform-origin: left;
    width: 390px;
    z-index: 3;
}

.backoffice-layout #sidebar #navBar ul li {
    display: flex;
    flex-direction: column;
    margin: .5rem 0;
    padding: 0;
    border-left: .125rem solid transparent;
    overflow: hidden;
}

.backoffice-layout {
    grid-template-columns: 0;
}

.menus-nav-layout #sidebar #navBar .btn {
    background-color: transparent;
}


.btn svg + span, button svg + span {
    margin-left: .5rem;
    text-align: left;
    color: #333 !important;
}

.backoffice-layout #mainHeader h1, .backoffice-layout #mainHeader h2 {
    color: #333!important;
    font-size: 1rem;
}

.btn.btn--menu {
    border: 1px solid black !important;
    color: #333 !important;
}

.bg-green {
    background-color: rgb(113, 147, 24) !important;
}

.bg-grey-light {
    background-color: #f2f2f2;
}

.bg-orange {
    background-color: rgb(244, 174, 29);
}

.bg-green-linear {
    background: linear-gradient(#ffffff,#ebf8e1, #77b82a);
}

.bg-orange-linear {
    background: linear-gradient(#ffffff,#FBF2E0FF, #F4AE1DFF);
}

.f-size-list {
    font-size: 14px;
}
.f-size-raise {
    font-size: 12px;
}

.raise-item-positive {
    padding: 4px;
    border-radius: 2px;
    background-color: rgba(58, 172, 58, 10%);
    color: rgb(58, 172, 58) !important;
    font-size: 12px;
}

.raise-item-negative {
    padding: 4px;
    border-radius: 2px;
    background-color: rgba(196, 49, 13, 10%);
    color: rgb(196, 49, 13) !important;
    font-size: 12px;
}

.raise-item-positive span {
    color: rgb(58, 172, 58) !important;
    font-size: 12px;
}

.raise-item-negative span {
    color: rgb(196, 49, 13) !important;
    font-size: 12px;
}

@media (min-width: 1024px) and (max-width: 1400px){
    .f-size-list {
        font-size: 12px;
    }
    .raise-item-positive span {
        color: rgb(58, 172, 58) !important;
        font-size: 7px;
    }

    .raise-item-negative span {
        color: rgb(196, 49, 13) !important;
        font-size: 7px;
    }
}

.btn span.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}

.btn span.badge.badge-danger{
    color: #fff;
    background-color: #dc3545;
}
</style>
