<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-12'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <div class="row justify-content-between">
                                <h3 class='card-title' v-if='detail.tempComponentId'>
                                    {{ detail.name }}
                                </h3>
                                <h3 class='card-title' v-else>Demande création d'un nouveau composant</h3>
                                <button v-if="!loading && detail.tempComponentId !== null && suppression"
                                        class="btn btn--color-danger"
                                        @click="openDeleteModal">
                                    <svg-bin />
                                </button>
                            </div>
                            <form class='mt-6'>
                                <fieldset>
                                    <legend>Composant</legend>
                                    <!-- TYPE COMPOSANT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select :class="{'is-invalid': v$.detail.componentTypeId.$error,}"
                                                    class='form-control'
                                                    v-model='detail.componentTypeId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(componentType, key) in componentTypes'
                                                        :key='key'
                                                        :value='componentType.componentTypeId'>
                                                    {{ componentType.name }}
                                                </option>
                                            </select>
                                            <p v-if='v$.detail.componentTypeId.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner un type de composant
                                            </p>
                                        </div>
                                    </div>
                                    <!-- QUALITE COMPOSANT -->
                                    <div class='row form-group align-items-center'>
                                        <label class='col-3 col-form-label'>Qualité du composant</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.componentQualityId'>
                                                <option :value="undefined"></option>
                                                <option v-for='(componentQuality, key) in componentQualities'
                                                        :key='key'
                                                        :value='componentQuality.componentQualityId'>
                                                    {{ componentQuality.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- UGS -->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>UGS</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <input type='text'-->
                                    <!--                                                   class='form-control'-->
                                    <!--                                                   v-model='detail.ugs'>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!-- REF FABRICANT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Référence fabriquant</label>
                                        <div class='col-6'>
                                            <input type='text'
                                                   class='form-control'
                                                   v-model='detail.manufacturerRef' >
                                        </div>
                                    </div>
                                    <!-- OUTILLAGE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Outillage</label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='tools'
                                                               :id="'tools_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.tools'>
                                                        <label class='custom-control-label'
                                                               :for="'tools_'+s.value"
                                                               :class="detail.tools === s.value ? s.class : ''">{{ s.label2
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- PRIX -->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Prix HT</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <input type='text'-->
                                    <!--                                                   class='form-control'-->
                                    <!--                                                   v-model='detail.price' >-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                </fieldset>
                                <fieldset>
                                    <legend>Appareil</legend>
                                    <!-- TYPE PRODUIT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.productTypeId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(productType, key) in productTypes'
                                                        :key='key'
                                                        :value='productType.productTypeId'>
                                                    {{ productType.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- MARQUE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Marque</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.brandId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(brand, key) in brands'
                                                        :key='key'
                                                        :value='brand.brandId'>
                                                    {{ brand.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- MODELE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Modèle</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.modelId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(model, key) in models'
                                                        :key='key'
                                                        :value='model.modelId'>
                                                    {{ model.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- COULEUR -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Couleur</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.colorId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(color, key) in colors'
                                                        :key='key'
                                                        :value='color.colorId'>
                                                    {{ color.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- CAPACITE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Capacité</label>
                                        <div class='col-6'>
                                            <div class='row'>
                                                <div class='col-3 pl-2'>
                                                    <select class='form-control'
                                                            v-model='detail.capacityId'
                                                            required
                                                            :disabled="!detail.unitId">
                                                        <option :value="undefined"></option>
                                                        <option v-for='(capacity, key) in capacities'
                                                                :key='key'
                                                                :value='capacity.capacityId'>
                                                            {{ capacity.value }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class='col-3'>
                                                    <select class='form-control'
                                                            v-model='detail.unitId'
                                                            required>
                                                        <option :value="undefined"></option>
                                                        <option v-for='(unit, key) in units'
                                                                :key='key'
                                                                :value='unit.unitId'>
                                                            {{ unit.shortName }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-center'>
                                <div class='col-auto'>
                                    <notif type='success'
                                           text='Sauvegarde effectuée'
                                           v-if='success'/>
                                    <notif type='error'
                                           text='Un problème est survenu durant la sauvegarde'
                                           :message='this.msgError'
                                           v-if='error'/>
                                </div>
                            </div>
                            <div class='row justify-content-between is-animated d-flex'>
                                <div class="col-auto">
                                    <button v-if="!loading && detail.tempComponentId !== null && modification"
                                            class="btn--color-success"
                                            @click="createComponent">
                                        <span>Nouvelle demande de création de composant</span>
                                        <svg-add />
                                    </button>
                                </div>
                                <div class='col-auto'>
                                    <button
                                        v-if='!loading && modification'
                                        class='btn--color-success'
                                        @click='submit'
                                        :disabled='v$.$error'>
                                        <span>Sauvegarder</span>
                                        <svg-floppy-disk />
                                    </button>
                                    <button
                                        v-if='loading'
                                        class='btn--color-success'
                                        type='button'
                                        disabled>
                                        <span>Sauvegarde en cours...</span>
                                        <figure class='spin--infinite mr-2'>
                                            <svg-floppy-disk />
                                        </figure>
                                    </button>
                                </div>
                                <div class='col-auto'>
                                    <div class="col-auto " v-if="detail.tempComponentId">
                                        <button
                                            v-if="!loadingCreate && suppression && detail.tempComponentId"
                                            class="btn--color-success"
                                            @click="create"
                                            :disabled="v$.$error">
                                            <span>Créer</span>
                                            <svg-floppy-disk />
                                        </button>
                                        <button
                                            v-if="loadingCreate"
                                            class="btn--color-success"
                                            type="button"
                                            disabled>
                                            <span>Creation en cours...</span>
                                            <figure class="spin--infinite mr-2">
                                                <svg-floppy-disk />
                                            </figure>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal suppression component -->
        <modal title="Suppression de la demande" :display="deleteModal" @close="closeDeleteModal" >
            <div class="modal-body">
                <div class="row justify-content-center">
                    <div class="col-12">
                        Êtes vous sûr de vouloir supprimer la demande de création de composant ?
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn--outline btn--color--danger" @click="closeDeleteModal" >
                    <svg-close />
                    <span>Annuler</span>
                </button>
                <button type="button" class="btn--color-danger" @click="deleteComponent" :disabled='v$.$error'>
                    <span>Supprimer</span>
                    <svg-bin />
                </button>
            </div>
        </modal>
    </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import modal from '../../components/Modal.vue';
import { useAuthStore } from '@/stores/auth.store';

export default {
    name: 'TempComponentDetail',
    components: {
        ...svgComponent,
        notif,
        modal
    },
    data() {
        return {
            detail: null,
            componentTypes: [],
            componentQualities: [],
            colors: [],
            units: [],
            brands: [],
            capacities: [],
            productTypes: [],
            models: [],
            places: [],
            categories: [],
            status: [
                {
                    value: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            deleteModal: false,
            lecture: false,
            modification: false,
            suppression: false,
            loadingCreate: false
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Demande création nouveau composant', 'Modification');
        this.lecture = await this.authStore.moduleRight('Demande création nouveau composant', 'Lecture');
        this.suppression = await this.authStore.moduleRight('Demande création nouveau composant', 'Suppression');
        if(!this.lecture)
            this.$router.push("/admin/temp-component");
        await this.getBrands();
        await this.getProductTypes();
        await this.getComponentTypes();
        await this.getComponentQualities();
        await this.getColors();
        await this.getUnits();
        await this.getPlaces();
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/temp-component');
            this.createNewDetail();
        } else {
            await this.getDetail();
        }
    },
    watch: {
        'detail.brandId': async function (val, oldVal) {
            if(val !== oldVal && this.detail.productTypeId)
                await this.getModels();
        },
        'detail.productTypeId' : async function (val, oldVal) {
            if (val !== oldVal && this.detail.brandId)
                await this.getModels();
        },
        'detail.unitId' : async function (val, oldVal) {
            if (val !== oldVal)
                await this.getCapacities();
        }
    },
    methods: {
        createNewDetail() {
            this.detail = {
                tempComponentId: null,
                ugs: null,
                price: null,
                manufacturerRef: null,
                compatibility: null,
                componentTypeId: null,
                componentQualityId: null,
                capacityId: null,
                value: null,
                unitId: null,
                colorId: null,
                brandId: null,
                brandName: null,
                productTypeId: null,
                productName: null,
                tools: false,
                modelId: null,
            };
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        createComponent() {
            this.createNewDetail();
            this.$router.push("create");
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/component/temp/${this.$route.params.id}`,
                );
                this.detail = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getModels() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/models/brand/${this.detail.brandId}/productType/${this.detail.productTypeId}`,
                );
                this.models = res.data.filter(m => m.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getComponentTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/componentType`,
                );
                this.componentTypes = res.data.filter(ct => ct.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getComponentQualities() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/componentQuality`,
                );
                this.componentQualities = res.data.filter(cq => cq.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getColors() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/color`,
                );
                this.colors = res.data.filter(c => c.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getUnits() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/unit`,
                );
                this.units = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getCapacities() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/capacities/unit/${this.detail.unitId}`,
                );
                this.capacities = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data.filter(p => p.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async create(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.error = false;
                this.success = false;
                this.loadingCreate = true;
                this.detail.status = true;
                this.detail.price = null;
                this.detail.models = [{ modelId: this.detail.modelId }];
                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/component`,
                        this.detail,
                    );
                    await this.deleteComponent();
                    this.success = true;
                    this.loadingCreate = false;
                    this.$router.push('/admin/component/'+res.data.componentId);
                } catch (err) {
                    this.error = true;
                    this.loadingCreate = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                this.detail.price = this.detail.price === '' ? null : this.detail.price;

                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/component/temp`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    this.goTo(res.data.tempComponentId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
        openDeleteModal() {
            this.deleteModal = true;
        },
        closeDeleteModal() {
            this.deleteModal = false;
        },
        async deleteComponent() {
            try{
                await this.axios.delete(
                    `${window.location.origin}/api/crd/component/temp/${this.$route.params.id}`
                );
                this.$router.push("/admin/temp-component");
            }catch (err) {
                console.log(err);
                this.error = true;
                this.msgError = err.response.data.message;
            } finally {
                this.closeDeleteModal();
            }
        }
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore()
    }),
    validations() {
        let base = {
            $autoDirty: true,
            detail: {
                componentTypeId: { required },
                price: { numeric }
            }
        };
        return base;
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
