<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-12'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <div class="row justify-content-between">
                                <h3 class='card-title' v-if='detail.tempModelId'>
                                    {{ detail.name2 }}
                                </h3>
                                <h3 class='card-title' v-else>
                                    Demande création d'un nouveau modèle
                                </h3>
                                <button v-if="!loading && detail.tempModelId !== null && suppression"
                                        class="btn btn--color-danger"
                                        @click="openDeleteModal">
                                    <svg-bin />
                                </button>
                            </div>
                            <form class='mt-6'>
                                <fieldset>
                                    <legend>Modèle</legend>
                                    <!-- NOM -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Nom<abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       :class="{'is-invalid': v$.detail.name.$error}"
                                                       v-model='detail.name' >
                                                <p v-if='v$.detail.name.$error' class='invalid-feedback'>Veuillez saisir
                                                    un nom</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- MARQUE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Marque <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <select :class="{'is-invalid': v$.detail.brandId.$error,}"
                                                        class='form-control'
                                                        v-model='detail.brandId'
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for='(brand, key) in brands'
                                                            :key='key'
                                                            :value='brand.brandId'>
                                                        {{ brand.name }}
                                                    </option>
                                                </select>
                                                <p v-if='v$.detail.brandId.$error'
                                                   class='invalid-feedback'>
                                                    Veuillez sélectionner une marque
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TYPE PRODUIT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <select :class="{'is-invalid': v$.detail.productTypeId.$error,}"
                                                        class='form-control'
                                                        v-model='detail.productTypeId'
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for='(productType, key) in productTypes'
                                                            :key='key'
                                                            :value='productType.productTypeId'>
                                                        {{ productType.name }}
                                                    </option>
                                                </select>
                                                <p v-if='v$.detail.productTypeId.$error'
                                                   class='invalid-feedback'>
                                                    Veuillez sélectionner un Type d'appareil
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- REF FOURNISSEUR -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Référence fournisseur</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.manufacturerRef' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- DATE SORTIE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Date de Sortie (mois année) <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='month'
                                                       class='form-control'
                                                       :class="{'is-invalid': v$.detail.releaseDate.$error}"
                                                       v-model='detail.releaseDate' >
                                                <p style='color: gray'>{{ detail.releaseDate }} </p>
                                                <p v-if='v$.detail.releaseDate.$error'
                                                   class='invalid-feedback'>
                                                    Veuillez saisir la date de sortie du modèle
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- COMMENTAIRE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Commentaire</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.comment' >
                                            </div>
                                        </div>
                                    </div>
                                    <!--                                    &lt;!&ndash; NFC &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>NFC</label>-->
                                    <!--                                        <div class='col-9'>-->
                                    <!--                                            <div class='row'>-->
                                    <!--                                                <div class='col'>-->
                                    <!--                                                    <div class='custom-control custom-radio custom-control-inline'-->
                                    <!--                                                         v-for='s in status'-->
                                    <!--                                                         :key='s.value'>-->
                                    <!--                                                        <input class='custom-control-input'-->
                                    <!--                                                               type='radio'-->
                                    <!--                                                               name='nfc'-->
                                    <!--                                                               :id="'nfc_'+s.value"-->
                                    <!--                                                               :value='s.value'-->
                                    <!--                                                               v-model='detail.nfc'>-->
                                    <!--                                                        <label class='custom-control-label'-->
                                    <!--                                                               :for="'nfc_'+s.value"-->
                                    <!--                                                               :class="detail.nfc === s.value ? s.class : ''">{{ s.label2-->
                                    <!--                                                               }}</label>-->
                                    <!--                                                    </div>-->
                                    <!--                                                </div>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; JACK &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Prise jack</label>-->
                                    <!--                                        <div class='col-9'>-->
                                    <!--                                            <div class='row'>-->
                                    <!--                                                <div class='col'>-->
                                    <!--                                                    <div class='custom-control custom-radio custom-control-inline'-->
                                    <!--                                                         v-for='s in status'-->
                                    <!--                                                         :key='s.value'>-->
                                    <!--                                                        <input class='custom-control-input'-->
                                    <!--                                                               type='radio'-->
                                    <!--                                                               name='jack'-->
                                    <!--                                                               :id="'jack_'+s.value"-->
                                    <!--                                                               :value='s.value'-->
                                    <!--                                                               v-model='detail.jack'>-->
                                    <!--                                                        <label class='custom-control-label'-->
                                    <!--                                                               :for="'jack_'+s.value"-->
                                    <!--                                                               :class="detail.jack === s.value ? s.class : ''">{{ s.label2-->
                                    <!--                                                               }}</label>-->
                                    <!--                                                    </div>-->
                                    <!--                                                </div>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; PROCESSEUR &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Processeur</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='text'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.processor' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; TAILLE ECRAN &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Taille écran (pouces)</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='number'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.screenSize' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; RESOLUTION &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Résolution écran (px)</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='text'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.screenResolution' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; CAMERA AVANT &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Caméra avant (MP)</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='number'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.cameraFront' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; CAMERA ARRIERE &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Caméra arrière (MP)</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='number'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.cameraBack' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; BATTERIE &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Batterie (mAh)</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='text'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.battery' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; DAS &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>DAS (W/kg)</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='text'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.das' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; RESEAUX &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Réseaux</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='text'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.network' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; ESIM &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>eSIM</label>-->
                                    <!--                                        <div class='col-9'>-->
                                    <!--                                            <div class='row'>-->
                                    <!--                                                <div class='col'>-->
                                    <!--                                                    <div class='custom-control custom-radio custom-control-inline'-->
                                    <!--                                                         v-for='s in status'-->
                                    <!--                                                         :key='s.value'>-->
                                    <!--                                                        <input class='custom-control-input'-->
                                    <!--                                                               type='radio'-->
                                    <!--                                                               name='esim'-->
                                    <!--                                                               :id="'esim_'+s.value"-->
                                    <!--                                                               :value='s.value'-->
                                    <!--                                                               v-model='detail.isEsim'>-->
                                    <!--                                                        <label class='custom-control-label'-->
                                    <!--                                                               :for="'esim_'+s.value"-->
                                    <!--                                                               :class="detail.isEsim === s.value ? s.class : ''">{{ s.label2-->
                                    <!--                                                               }}</label>-->
                                    <!--                                                    </div>-->
                                    <!--                                                </div>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; TYPE CHARGEUR &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Type de Chargeur</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <select class='form-control'-->
                                    <!--                                                        v-model='detail.chargerType'-->
                                    <!--                                                        required>-->
                                    <!--                                                    <option :value="undefined"></option>-->
                                    <!--                                                    <option v-for='(chargerType, key) in chargerTypes'-->
                                    <!--                                                            :key='key'-->
                                    <!--                                                            :value='chargerType.id'>-->
                                    <!--                                                        {{ chargerType.label }}-->
                                    <!--                                                    </option>-->
                                    <!--                                                </select>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; GROUPE &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Groupe</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <select class='form-control'-->
                                    <!--                                                        v-model='detail.groupId'-->
                                    <!--                                                        required>-->
                                    <!--                                                    <option :value="undefined"></option>-->
                                    <!--                                                    <option v-for='(group, key) in groups'-->
                                    <!--                                                            :key='key'-->
                                    <!--                                                            :value='group.groupId'>-->
                                    <!--                                                        {{ group.name }}-->
                                    <!--                                                    </option>-->
                                    <!--                                                </select>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; META DESC &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Méta description</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='text'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.metaDescription' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; BENCHMARK &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Benchmark AnTuTu</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='number'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.benchmarkAntutu' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; BENCHMARK &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Benchmark DXOMARK photo</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='number'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.benchmarkDxomarkPhoto' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; BENCHMARK &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Benchmark DXOMARK écran</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='number'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.benchmarkDxomarkEcran' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; CAPTEUR PHOTO &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Nombre de capteur photo</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='number'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.photoSensor' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; OLED SCREEN &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Ecran OLED</label>-->
                                    <!--                                        <div class='col-9'>-->
                                    <!--                                            <div class='row'>-->
                                    <!--                                                <div class='col'>-->
                                    <!--                                                    <div class='custom-control custom-radio custom-control-inline'-->
                                    <!--                                                         v-for='s in status'-->
                                    <!--                                                         :key='s.value'>-->
                                    <!--                                                        <input class='custom-control-input'-->
                                    <!--                                                               type='radio'-->
                                    <!--                                                               name='oled'-->
                                    <!--                                                               :id="'oled_'+s.value"-->
                                    <!--                                                               :value='s.value'-->
                                    <!--                                                               v-model='detail.oledScreen'>-->
                                    <!--                                                        <label class='custom-control-label'-->
                                    <!--                                                               :for="'oled_'+s.value"-->
                                    <!--                                                               :class="detail.oledScreen === s.value ? s.class : ''">{{ s.label2 }}</label>-->
                                    <!--                                                    </div>-->
                                    <!--                                                </div>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    &lt;!&ndash; RAFRAICHISSEMENT ECRAN &ndash;&gt;-->
                                    <!--                                    <div class='row form-group'>-->
                                    <!--                                        <label class='col-3 col-form-label'>Rafraîchissement écran (Hz)</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <div class='col-6'>-->
                                    <!--                                                <input type='number'-->
                                    <!--                                                       class='form-control'-->
                                    <!--                                                       v-model='detail.refreshRate' >-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                </fieldset>
                            </form>
                            <div class='card-footer'>
                                <div class='row justify-content-center'>
                                    <div class='col-auto'>
                                        <notif type='success'
                                               text='Sauvegarde effectuée'
                                               v-if='success'/>
                                        <notif type='error'
                                               text='Un problème est survenu durant la sauvegarde'
                                               :message='this.msgError'
                                               v-if='error'/>
                                    </div>
                                </div>
                                <div class='row is-animated d-flex justify-content-between'>
                                    <div class='col-auto'>
                                        <button v-if='!loading && detail.tempModelId && modification'
                                                class='btn--color-success'
                                                @click='createModel'>
                                            <span>Nouvelle demande de création de modèle</span>
                                            <svg-add />
                                        </button>
                                    </div>
                                    <div class='col-auto'>
                                        <button
                                            v-if='!loading && modification'
                                            class='btn--color-success'
                                            @click='submit'
                                            :disabled='v$.$error'>
                                            <span>Sauvegarder</span>
                                            <svg-floppy-disk />
                                        </button>
                                        <button
                                            v-if='loading'
                                            class='btn--color-success'
                                            type='button'
                                            disabled>
                                            <span>Sauvegarde en cours...</span>
                                            <figure class='spin--infinite mr-2'>
                                                <svg-floppy-disk />
                                            </figure>
                                        </button>
                                    </div>
                                    <div class='col-auto'>
                                        <div class="col-auto " v-if="detail.tempModelId">
                                            <button
                                                v-if="!loadingCreate && suppression && detail.tempModelId"
                                                class="btn--color-success"
                                                @click="create"
                                                :disabled="v$.$error">
                                                <span>Créer</span>
                                                <svg-floppy-disk />
                                            </button>
                                            <button
                                                v-if="loadingCreate"
                                                class="btn--color-success"
                                                type="button"
                                                disabled>
                                                <span>Creation en cours...</span>
                                                <figure class="spin--infinite mr-2">
                                                    <svg-floppy-disk />
                                                </figure>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal suppression modèle -->
        <modal title="Suppression de la demande" :display="deleteModal" @close="closeDeleteModal" >
            <div class="modal-body">
                <div class="row justify-content-center">
                    <div class="col-12">
                        Êtes vous sûr de vouloir supprimer la demande de création de modèle ?
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn--outline btn--color--danger" @click="closeDeleteModal" >
                    <svg-close />
                    <span>Annuler</span>
                </button>
                <button type="button" class="btn--color-danger" @click="deleteModel" :disabled='v$.$error'>
                    <span>Supprimer</span>
                    <svg-bin />
                </button>
            </div>
        </modal>
    </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import { useAuthStore } from '@/stores/auth.store';
import modal from '@/components/Modal.vue';

export default {
    name: 'TempModelDetail',
    components: {
        modal,
        ...svgComponent,
        notif,
    },
    data() {
        return {
            detail: null,
            brands: [],
            models: [],
            repairTypes: [],
            capacities: [],
            productTypes: [],
            componentTypes: [],
            componentQualities: [],
            groups: [],
            colors: [],
            units: [],
            status: [
                {
                    value: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            lecture: false,
            modification: false,
            suppression: false,
            loadingCreate: false,
            deleteModal: false,
            chargerTypes :[
                { id:'lightning', label:'Lightning' },
                { id:'usb-c', label:'USB C' },
                { id:'micro-usb', label:'Micro USB' },
                { id:'magnetique', label:'Chargeur magnétique' },
            ]
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Demande création nouveau modèle', 'Modification');
        this.lecture = await this.authStore.moduleRight('Demande création nouveau modèle', 'Lecture');
        this.suppression = await this.authStore.moduleRight('Demande création nouveau modèle', 'Suppression');
        if (!this.lecture)
            this.$router.push('/admin/temp-model');
        await this.getBrands();
        await this.getProductTypes();
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/temp-model');
            this.createNewDetail();
        } else {
            await this.getDetail();
        }
    },
    methods: {
        createNewDetail() {
            this.detail = {
                tempModelId: null,
                name: null,
                manufacturerRef: null,
                releaseDate: null,
                comment: null,
                status: true,
                brandId: null,
                productTypeId: null,
                processor: null,
                screenSize: null,
                screenResolution: null,
                cameraFront: null,
                cameraBack: null,
                nfc: null,
                jack: null,
                battery: null,
                das: null,
                network: null,
                metaDescription: null,
                isEsim: false,
                chargerType: null,
                groupId: null,
                benchmarkAntutu: null,
                photoSensor: null,
                oledScreen: null,
                refreshRate: null,
                benchmarkDxomarkPhoto: null,
                benchmarkDxomarkEcran: null
            };
        },
        createModel() {
            this.createNewDetail();
            this.$router.push('create');
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/model/temp/${this.$route.params.id}`,
                );
                this.detail = res.data;
                this.detail.name2 = (this.detail.brandName + ' ' + this.detail.name).toLowerCase();
                this.detail.name2 = this.detail.name2.charAt(0).toUpperCase() + this.detail.name2.slice(1);
            } catch (err) {
                console.log(err);
            }
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        // async getGroups() {
        //     try {
        //         const res = await this.axios.get(
        //             `${window.location.origin}/api/crd/essentials/group`,
        //         );
        //         this.groups = this.groups = this.detail.brandId ? res.data.filter(g => g.brandId === this.detail.brandId) : res.data;
        //     } catch (err) {
        //         console.log(err);
        //     }
        // },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        async create(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                Object.keys(this.detail).forEach(key => {
                    this.detail[key] = this.detail[key] === "" ? null : this.detail[key];
                });
                this.detail.status = true;
                try {
                    let res = await this.axios.post(
                        `${window.location.origin}/api/crd/model`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    await this.deleteModel();
                    this.$router.push('/admin/model/'+res.data.modelId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                Object.keys(this.detail).forEach(key => {
                    this.detail[key] = this.detail[key] === "" ? null : this.detail[key];
                });
                try {
                    let res = await this.axios.post(
                        `${window.location.origin}/api/crd/model/temp`,
                        this.detail,
                    );
                    this.detail.tempModelId= res.data.tempModelId;
                    this.success = true;
                    this.loading = false;
                    this.goTo(res.data.tempModelId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
        openDeleteModal() {
            this.deleteModal = true;
        },
        closeDeleteModal() {
            this.deleteModal = false;
        },
        async deleteModel() {
            try{
                await this.axios.delete(
                    `${window.location.origin}/api/crd/model/temp/${this.$route.params.id}`
                );
                this.$router.push("/admin/temp-model");
            }catch (err) {
                console.log(err);
                this.error = true;
                this.msgError = err.response.data.message;
            } finally {
                this.closeDeleteModal();
            }
        }
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore(),
    }),
    validations() {
        let base = {
            detail: {
                $autoDirty: true,
                name: { required },
                brandId: { required },
                productTypeId: { required },
                releaseDate: { required }
            },
        };

        return base;
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
