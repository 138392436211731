<template>
    <section id="mainContent" v-if="detail">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h3 class="card-title" v-if="detail.repairTypeId">
                                {{ detail.name }}
                            </h3>
                            <h3 class="card-title" v-else>
                                Création d'une réparation
                            </h3>
                            <form class="mt-6">
                                <fieldset>
                                    <legend>Réparation</legend>
                                    <!-- TYPE REPARATION -->
                                    <div class="row form-group">
                                        <label class="col-3 col-form-label">Type <abbr>*</abbr></label>
                                        <div class="col-6">
                                            <div class="col-6">
                                                <select :class="{'is-invalid': v$.detail.repairTypeId.$error,}"
                                                        class="form-control"
                                                        v-model="detail.repairTypeId"
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for="(repairType, key) in repairTypes"
                                                            :key="key"
                                                            :value="repairType.repairTypeId">
                                                        {{ repairType.name }}
                                                    </option>
                                                </select>
                                                <p v-if="v$.detail.repairTypeId.$error"
                                                   class="invalid-feedback">
                                                    Veuillez sélectionner un type de réparation
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TYPE COMPOSANT -->
                                    <div class="row form-group">
                                        <label class="col-3 col-form-label">Type de composant</label>
                                        <div class="col-6">
                                            <div class="col-6">
                                                <select class="form-control"
                                                        v-model="detail.componentTypeId">
                                                    <option :value="undefined"></option>
                                                    <option v-for="(componentType, key) in componentTypes"
                                                            :key="key"
                                                            :value="componentType.componentTypeId">
                                                        {{ componentType.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- QUALITE COMPOSANT -->
                                    <div class="row form-group">
                                        <label class="col-3 col-form-label">Qualité du composant</label>
                                        <div class="col-6">
                                            <div class="col-6">
                                                <select class="form-control"
                                                        v-model="detail.componentQualityId">
                                                    <option :value="undefined"></option>
                                                    <option v-for="(componentQuality, key) in componentQualities"
                                                            :key="key"
                                                            :value="componentQuality.componentQualityId">
                                                        {{ componentQuality.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- COULEUR -->
                                    <div class="row form-group">
                                        <label class="col-3 col-form-label">Couleur</label>
                                        <div class="col-6">
                                            <div class="col-6">
                                                <select class="form-control"
                                                        v-model="detail.colorId"
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for="(color, key) in colors"
                                                            :key="key"
                                                            :value="color.colorId">
                                                        {{ color.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- CAPACITE -->
                                    <div class="row form-group">
                                        <label class="col-3 col-form-label">Capacité</label>
                                        <div class="col-6">
                                            <div class="row">
                                                <div class='col-3 pl-4'>
                                                    <select class='form-control'
                                                            v-model='detail.capacityId'
                                                            required>
                                                        <option :value="undefined"></option>
                                                        <option v-for='(capacity, key) in capacities'
                                                                :key='key'
                                                                :value='capacity.capacityId'>
                                                            {{ capacity.value }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-3">
                                                    <select class="form-control"
                                                            v-model="detail.unitId"
                                                            required>
                                                        <option :value="undefined"></option>
                                                        <option v-for="(unit, key) in units"
                                                                :key="key"
                                                                :value="unit.unitId">
                                                            {{ unit.shortName }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ETAT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Etat <abbr>*</abbr></label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='status'
                                                               :class="{'is-invalid': v$.detail.status.$error}"
                                                               :id="'status_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.status'>
                                                        <label class='custom-control-label'
                                                               :for="'status_'+s.value"
                                                               :class="detail.status === s.value ? s.class : ''">{{ s.label
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row' v-if='v$.detail.status.$error'>
                                                <div class='col'>
                                                    <input type='hidden' class='form-control is-invalid' >
                                                    <p class='invalid-feedback'>
                                                        Veuillez sélectionner un état
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- PRIX -->
                                    <div class="row form-group">
                                        <label class="col-3 col-form-label">Prix TTC <abbr>*</abbr></label>
                                        <div class="col-6">
                                            <div class="col-6">
                                                <input type="number"
                                                       min="0"
                                                       class="form-control"
                                                       :class="{'is-invalid': v$.detail.price.$error}"
                                                       v-model="detail.price">
                                                <p v-if="v$.detail.price.$error" class="invalid-feedback">Veuillez saisir prix</p>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>Appareil</legend>
                                    <!-- MARQUE -->
                                    <div class="row form-group">
                                        <label class="col-3 col-form-label">Marque <abbr>*</abbr></label>
                                        <div class="col-6">
                                            <div class="col-6">
                                                <select :class="{'is-invalid': v$.detail.brandId.$error,}"
                                                        class="form-control"
                                                        v-model="detail.brandId"
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for="(brand, key) in brands"
                                                            :key="key"
                                                            :value="brand.brandId">
                                                        {{ brand.name }}
                                                    </option>
                                                </select>
                                                <p v-if="v$.detail.brandId.$error"
                                                   class="invalid-feedback">
                                                    Veuillez sélectionner une marque
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TYPE PRODUIT -->
                                    <div class="row form-group">
                                        <label class="col-3 col-form-label">Type <abbr>*</abbr></label>
                                        <div class="col-6">
                                            <div class="col-6">
                                                <select :class="{'is-invalid': v$.detail.productTypeId.$error,}"
                                                        class="form-control"
                                                        v-model="detail.productTypeId"
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for="(productType, key) in productTypes"
                                                            :key="key"
                                                            :value="productType.productTypeId">
                                                        {{ productType.name }}
                                                    </option>
                                                </select>
                                                <p v-if="v$.detail.productTypeId.$error"
                                                   class="invalid-feedback">
                                                    Veuillez sélectionner un Type d'appareil
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- MODELE -->
                                    <div class="row form-group">
                                        <label class="col-3 col-form-label">Modèle <abbr>*</abbr></label>
                                        <div class="col-6">
                                            <div class="col-6">
                                                <select :class="{'is-invalid': v$.detail.modelId.$error,}"
                                                        class="form-control"
                                                        v-model="detail.modelId"
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for="(model, key) in models"
                                                            :key="key"
                                                            :value="model.modelId">
                                                        {{ model.name }}
                                                    </option>
                                                </select>
                                                <p v-if="v$.detail.modelId.$error"
                                                   class="invalid-feedback">
                                                    Veuillez sélectionner un modèle
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <div class="card-footer">
                                <div class="row justify-content-center">
                                    <div class="col-auto">
                                        <notif type="success"
                                               text="Sauvegarde effectuée"
                                               v-if="success"/>
                                        <notif type='error'
                                               text='Un problème est survenu durant la sauvegarde'
                                               :message='this.msgError'
                                               v-if='error'/>
                                    </div>
                                </div>
                                <div class="row is-animated d-flex justify-content-between">
                                    <div class="col-auto">
                                        <button v-if="!loading && detail.repairId && modification"
                                                class="btn--color-success"
                                                @click="createRepair">
                                            <span>Créer une nouvelle réparation</span>
                                            <svg-add />
                                        </button>
                                        <button v-if="!loading && detail.repairId && modification"
                                                class="btn--color-success ml-2"
                                                @click="createSameRepair">
                                            <span>Créer une nouvelle réparation à partir de celle-ci</span>
                                            <svg-add />
                                        </button>
                                    </div>
                                    <div class="col-auto ">
                                        <button
                                            v-if="!loading && modification"
                                            class="btn--color-success"
                                            @click="submit"
                                            :disabled="v$.$error">
                                            <span>Sauvegarder</span>
                                            <svg-floppy-disk />
                                        </button>
                                        <button
                                            v-if="loading"
                                            class="btn--color-success"
                                            type="button"
                                            disabled>
                                            <span>Sauvegarde en cours...</span>
                                            <figure class="spin--infinite mr-2">
                                                <svg-floppy-disk />
                                            </figure>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import { useAuthStore } from '@/stores/auth.store';

export default {
    name: 'RepairDetail',
    components: {
        ...svgComponent,
        notif,
    },
    data() {
        return {
            detail: null,
            brands: [],
            models: [],
            repairTypes: [],
            capacities: [],
            productTypes: [],
            componentTypes: [],
            componentQualities: [],
            colors:[],
            units:[],
            status: [
                {
                    value: true,
                    label: 'Actif',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    class: 'text-danger',
                },
            ],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            lecture: false,
            modification: false
        };
    },
    async mounted() {
        await this.getBrands();
        await this.getProductTypes();
        await this.getComponentTypes();
        await this.getComponentQualities();
        await this.getRepairTypes();
        await this.getColors();
        await this.getUnits();
        this.modification = await this.authStore.moduleRight('Administration des réparations', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des réparations', 'Lecture');
        if (this.$route.params.id === "create") {
            if(!this.modification)
                this.$router.push('/admin/repair');
            this.createNewDetail();
        } else {
            await this.getDetail();
        }
        if(!this.lecture)
            this.$router.push("/admin/repair");
    },
    watch: {
        'detail.brandId': async function (val, oldVal) {
            if(val !== oldVal && this.detail.productTypeId)
                await this.getModels();
        },
        'detail.productTypeId' : async function (val, oldVal) {
            if (val !== oldVal && this.detail.brandId)
                await this.getModels();
        },
        'detail.unitId' : async function (val, oldVal) {
            if (val !== oldVal)
                await this.getCapacities();
        }
    },
    methods: {
        createNewDetail() {
            if (this.$route.params.id === "create") {
                this.detail = {
                    repairId: null,
                    price:null,
                    repairTypeId:null,
                    componentTypeId:null,
                    componentQualityId:null,
                    modelId:null,
                    brandId:null,
                    productTypeId:null,
                    capacityId:null,
                    value:null,
                    unitId:null,
                    colorId:null,
                    status: true
                };
            }else {
                this.detail.repairId = null;
            }

        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/repair/${this.$route.params.id}/detail`,
                );
                this.detail = res.data;
                this.detail.name = (this.detail.repairType + ' ' + this.detail.componentType +' ' + this.detail.brandName + ' ' +  this.detail.modelName).toLowerCase();
                this.detail.name = this.detail.name.charAt(0).toUpperCase()+this.detail.name.slice(1);
            } catch (err) {
                console.log(err);
            }
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getModels() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/models/brand/${this.detail.brandId}/productType/${this.detail.productTypeId}`,
                );
                this.models = res.data.filter(m => m.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getComponentTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/componentType`,
                );
                this.componentTypes = res.data.filter(ct => ct.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getComponentQualities() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/componentQuality`,
                );
                this.componentQualities = res.data.filter(cq => cq.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getRepairTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/repairType`,
                );
                this.repairTypes = res.data.filter(rt => rt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getColors() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/color`,
                );
                this.colors = res.data.filter(c => c.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getUnits() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/unit`,
                );
                this.units = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getCapacities() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/capacities/unit/${this.detail.unitId}`,
                );
                this.capacities = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        createRepair() {
            this.$router.push('create');
            this.createNewDetail();
        },
        createSameRepair() {
            this.$router.push('create-same');
            this.createNewDetail();
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;

                try {
                    let res = await this.axios.post(
                        `${window.location.origin}/api/crd/repair`,
                        this.detail,
                    );
                    this.detail.repairId = res.data.repairId;
                    this.success = true;
                    this.loading = false;
                    this.goTo(res.data.repairId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore()
    }),
    validations() {
        let base = {
            detail: {
                $autoDirty: true,
                brandId: { required },
                modelId: { required },
                repairTypeId: { required },
                productTypeId: { required },
                price: { required },
                status: { required }
            },
        };

        return base;
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
