<template>
    <section id="mainContent" v-if="detail">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <div class="row justify-content-between">
                                <h3 class="card-title" v-if="detail.gencode">
                                    {{ detail.descriptionArticle }}
                                </h3>
                                <button v-if="!loading && detail.gencode !== null && suppression"
                                        class="btn btn--color-danger"
                                        @click="openDeleteModal">
                                    <svg-bin />
                                </button>
                            </div>
                            <div class='row'>
                                <div class='col-sm-6 mt-6'>
                                    <form>
                                        <fieldset>
                                            <legend>Appareil</legend>
                                            <!-- GENCODE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Gencode</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='text'
                                                               class='form-control'
                                                               v-model='detail.gencode'
                                                               readonly >
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- GRADE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Grade</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='text'
                                                               class='form-control'
                                                               v-model='detail.idEtat'
                                                               readonly >

                                                    </div>
                                                </div>
                                            </div>
                                            <!-- SERIAL NUMBER -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Numéro de série / IMEI</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='text'
                                                               class='form-control'
                                                               v-model='detail.numeroSerieArticle'
                                                               readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- PRIX ACHAT-->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Prix achat TTC</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='number'
                                                               min='0'
                                                               class='form-control'
                                                               v-model='detail.prixUnitaireAchat'
                                                               readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- PRIX Vente-->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Prix vente TTC</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='number'
                                                               min='0'
                                                               class='form-control'
                                                               v-model='detail.prixUnitaireVente'
                                                               readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- LIEU-->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Lieu</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='text'
                                                               class='form-control'
                                                               v-model='detail.placeName'
                                                               readonly>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <legend>Associer le produit</legend>
                                            <!-- MARQUE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Marque <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.brandId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.brandId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(brand, key) in brands'
                                                                    :key='key'
                                                                    :value='brand.brandId'>
                                                                {{ brand.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.brandId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner une marque
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- TYPE PRODUIT -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Type <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.idTypeProduit.$error,}"
                                                                class='form-control'
                                                                v-model='detail.idTypeProduit'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(productType, key) in productTypes'
                                                                    :key='key'
                                                                    :value='productType.productTypeId'>
                                                                {{ productType.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.idTypeProduit.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner un Type d'appareil
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- MODELE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Modèle <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.modelId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.modelId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(model, key) in models'
                                                                    :key='key'
                                                                    :value='model.modelId'>
                                                                {{ model.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.modelId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner un modèle
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- COULEUR -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Couleur</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select class='form-control'
                                                                v-model='detail.colorId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(color, key) in colors'
                                                                    :key='key'
                                                                    :value='color.colorId'>
                                                                {{ color.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- CAPACITE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Capacité</label>
                                                <div class='col-6'>
                                                    <div class='row'>
                                                        <div class='col-6 pl-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.romCapacityId'
                                                                    required
                                                                    :disabled='!detail.romUnitId'>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(capacity, key) in capacities'
                                                                        :key='key'
                                                                        :value='capacity.capacityId'>
                                                                    {{ capacity.value }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class='col-6 pr-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.romUnitId'
                                                                    required>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(unit, key) in units'
                                                                        :key='key'
                                                                        :value='unit.unitId'>
                                                                    {{ unit.shortName }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- RAM -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>RAM</label>
                                                <div class='col-6'>
                                                    <div class='row'>
                                                        <div class='col-6 pl-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.ramCapacityId'
                                                                    :disabled='!detail.ramUnitId'>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(capacity, key) in ramCapacities'
                                                                        :key='key'
                                                                        :value='capacity.capacityId'>
                                                                    {{ capacity.value }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class='col-6 pr-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.ramUnitId'
                                                                    required>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(unit, key) in ramUnits'
                                                                        :key='key'
                                                                        :value='unit.unitId'>
                                                                    {{ unit.shortName }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- PRODUIT -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Produit <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.newIdProduct.$error,}"
                                                                class='form-control'
                                                                v-model='detail.newIdProduct'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(product, key) in products'
                                                                    :key='key'
                                                                    :value='product.productId'>
                                                                {{ product.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.newIdProduct.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner un produit
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-center'>
                                <div class='col-auto'>
                                    <notif type='success'
                                           text='Sauvegarde effectuée'
                                           v-if='success'/>
                                    <notif type='error'
                                           text='Un problème est survenu durant la sauvegarde'
                                           :message='this.msgError'
                                           v-if='error'/>
                                </div>
                            </div>
                            <div class='row is-animated d-flex justify-content-end'>
                                <div class='col-auto'>
                                    <button
                                        v-if='!loading && modification'
                                        class='btn--color-success'
                                        @click='submit'
                                        :disabled='v$.$error'>
                                        <span>Sauvegarder</span>
                                        <svg-floppy-disk />
                                    </button>
                                    <button
                                        v-if='loading'
                                        class='btn--color-success'
                                        type='button'
                                        disabled>
                                        <span>Sauvegarde en cours...</span>
                                        <figure class='spin--infinite mr-2'>
                                            <svg-floppy-disk />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal suppression  -->
        <modal title="Suppression de l'appareil temporaire" :display="deleteModal" @close="closeDeleteModal" >
            <div class="modal-body">
                <div class="row justify-content-center">
                    <div class="col-12">
                        Êtes vous sûr de vouloir supprimer ?
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn--outline btn--color--danger" @click="closeDeleteModal" >
                    <svg-close />
                    <span>Annuler</span>
                </button>
                <button type="button" class="btn--color-danger" @click="deleteTempDevice">
                    <span>Supprimer</span>
                    <svg-bin />
                </button>
            </div>
        </modal>
    </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import { useAuthStore } from '@/stores/auth.store';
import { useProductStore } from '@/stores/product.store';
import { useLoader } from '@/stores/loader';
import modal from '../../components/Modal.vue';

export default {
    name: 'TempDeviceDetail',
    components: {
        ...svgComponent,
        notif,
        modal
    },
    data() {
        return {
            detail: null,
            brands: [],
            models: [],
            capacities: [],
            ramCapacities : [],
            productTypes: [],
            colors:[],
            units:[],
            ramUnits:[],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            lecture: false,
            modification: false,
            suppression: false,
            deleteModal: false,
            places: [],
            products: []
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Appareil temporaire', 'Modification');
        this.lecture = await this.authStore.moduleRight('Appareil temporaire', 'Lecture');
        this.suppression = await this.authStore.moduleRight('Appareil temporaire', 'Suppression');
        if (!this.lecture)
            this.$router.push("/admin/temp-device");
        await this.getDetail();
        await this.getBrands();
        await this.getProductTypes();
        // await this.getModels();
        // await this.getProducts();
        await this.getRamUnits();
        await this.getUnits();
        await this.getColors();
    },
    watch: {
        'success' : async function (val, oldVal) {
            if(val !== oldVal && val === true)
                await this.getDetail();
        },
        'detail.brandId': async function(val, oldVal) {
            if (val !== oldVal)
                await this.getModels();
        },
        'detail.idTypeProduit': async function(val, oldVal) {
            if (val !== oldVal)
                await this.getModels();
        },
        'detail.modelId' : async function(val, oldVal) {
            if (val !== oldVal)
                await this.getProducts();
        },
        'detail.romUnitId': async function(val, oldVal) {
            if (val !== oldVal) {
                await this.getProducts();
                if(this.detail.romUnitId)
                    await this.getCapacities();
            }
        },
        'detail.romCapacityId': async function(val, oldVal) {
            if (val !== oldVal) {
                await this.getProducts();
            }
        },
        'detail.ramUnitId': async function(val, oldVal) {
            if (val !== oldVal) {
                await this.getProducts();
                if(this.detail.ramUnitId)
                    await this.getRamCapacities();
            }
        },
        'detail.ramCapacityId': async function(val, oldVal) {
            if (val !== oldVal) {
                await this.getProducts();
            }
        },
        'detail.colorId': async function(val, oldVal) {
            if (val !== oldVal)
                await this.getProducts();
        }
    },
    methods: {
        async getDetail() {
            try {
                this.loader.show('load');
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/device/temp/${this.$route.params.gencode}`,
                );
                this.detail = res.data;
            } catch (err) {
                console.log(err);
            } finally {
                this.loader.hide('load');
            }
        },
        async getModels() {
            try {
                if (this.detail.brandId && this.detail.idTypeProduit) {
                    const res = await this.axios.get(
                        `${window.location.origin}/api/crd/models/brand/${this.detail.brandId}/productType/${this.detail.idTypeProduit}`,
                    );
                    this.models = res.data;
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getProducts() {
            if (this.productStore.getList.length === 0)
                await this.productStore.getProducts();
            this.products = this.productStore.getList;
            this.products =  this.products.reduce((curr, prod)=>{
                if(prod.brandId === this.detail.brandId && prod.productTypeId === this.detail.idTypeProduit
                && prod.modelId === this.detail.modelId)
                    curr.push(prod);
                return curr;
            },[]);
            if(this.detail.colorId) {
                this.products =  this.products.reduce((curr, prod)=>{
                    if(prod.colorId === this.detail.colorId)
                        curr.push(prod);
                    return curr;
                },[]);
            }
            if(this.detail.romCapacityId) {
                this.products =  this.products.reduce((curr, prod)=>{
                    if(prod.capacityId === this.detail.romCapacityId)
                        curr.push(prod);
                    return curr;
                },[]);
            }
            this.products.map(product => {
                product.name = (product.brandName + ' ' + product.modelName + ' ' + product.color + ' ' + product.rom).toLowerCase();
                product.name += product.ram ? ' (' + product.ram + ' de ram)'.toLowerCase() : '';
                product.name = product.name.charAt(0).toUpperCase() + product.name.slice(1);
            });
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getUnits() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/unit`,
                );
                this.units = res.data;
            } catch (err) {
                console.log(err);
            }
        },async getRamUnits() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/unit`,
                );
                this.ramUnits = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getCapacities() {
            try {
                if (typeof this.detail.romUnitId !== 'undefined') {
                    const res = await this.axios.get(
                        `${window.location.origin}/api/crd/capacities/unit/${this.detail.romUnitId}`,
                    );
                    this.capacities = res.data.filter(c => c.status === true);
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getRamCapacities() {
            try {
                if (typeof this.detail.romUnitId !== 'undefined') {
                    const res = await this.axios.get(
                        `${window.location.origin}/api/crd/capacities/unit/${this.detail.ramUnitId}`,
                    );
                    this.ramCapacities = res.data.filter(c => c.status === true);
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getColors() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/color`,
                );
                this.colors = res.data.filter(c => c.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        openDeleteModal() {
            this.deleteModal = true;
        },
        closeDeleteModal() {
            this.deleteModal = false;
        },
        async deleteTempDevice() {
            try{
                await this.axios.delete(
                    `${window.location.origin}/api/crd/device/temp/${this.$route.params.gencode}`
                );
                this.$router.push("/admin/temp-device");
            }catch (err) {
                console.log(err);
                this.error = true;
                this.msgError = err.response.data.message;
            } finally {
                this.closeDeleteModal();
            }
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;

                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/device/temp/${this.detail.gencode}`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    this.$router.push("/admin/temp-device");
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore(),
        productStore: useProductStore(),
        loader: useLoader()
    }),
    validations() {
        return {
            detail: {
                $autoDirty: true,
                brandId: { required },
                idTypeProduit: { required },
                modelId: { required },
                newIdProduct: { required },
            },
        };
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
